export enum RoutesEnum {
  //Users
  USER_DETAILS = "user/details",
  VERIFY_USER_INVITATION_LINK = "user/verify-invitation-token",
  INVITED_USER_REGISTRATION = "user/invited-user-registration",
  USER_REGISTRATION = "user/registration",
  UPDATE_USER = "user/edit-profile",
  VERIFY_INDIVIDUAL_USER_REGISTRATION_LINK = "user/verify-registration-token",
  DELETE_SINGLE_NOTIFICATION = "user/delete-single-notification",
  DELETE_ALL_NOTIFICATION = "user/delete-all-notification",

  //Organization
  ORG_MANAGE_USER_INVITE = 'organization/user/request-for-new-member',
  ORG_ROLE_MASTER_DATA = 'organization/filters/application-role',
  ORG_GET_USER_LIST = 'organization/filters/user-list',
  ORG_GET_FILTER_GROUP_LIST = 'organization/filters/group-list',
  ORG_GET_USER_LIST_SEARCH_FILTER = "organization/user/get-org-user-list",
  ORG_MANAGE_USER_RESEND_INVITE = 'organization/user/resend-invite',
  ORG_MANAGE_USER_CHANGE_STATUS = 'organization/user/change-user-status',
  ORG_MANAGE_USER_CHANGE_ROLE = 'organization/user/change-user-role',
  ORG_PROJECT_REPORT_TYPE_LIST = 'organization/filters/report-type-list',
  ORG_MANAGE_USER_CHECK_ASSOCIATED_SITES = 'organization/user/check-associated-sites',

  /** Organization Dashboard */
  ORG_DASHBOARD_RECENT_PROJECT_LIST = "organization/dashboard/get-recent-project-list",
  ORG_DASHBOARD_PROJECT_CHART_LIST = "organization/dashboard/get-project-chart-list",
  ORG_DASHBOARD_RECENT_PROJECT_PUBLISHED_LIST = "organization/dashboard/get-recent-published-projects",

  /** Organization Project */
  ORG_GET_PROJECT_LIST = "organization/project/get-project-list",
  ORG_GET_PROJECT_DETAILS = "organization/project/get-project-details/{project_slug}",
  ORG_GET_PROJECT_ROLE_MASTER_DATA = 'organization/project/get-project-role',
  ORG_GET_PROJECT_DIRECT_PERMISSION_MASTER_DATA = 'organization/project/get-project-direct-permissions',
  ORG_GET_TAG_LIST = 'organization/project/get-project-tag-list',
  ORG_CHECK_PROJECT_UNIQUE_SLUG = 'organization/project/check-unique-slug',
  ORG_CHECK_LIMIT = 'organization/project/check-limit-org',
  ORG_CREATE_PROJECT = 'organization/project/create-project',
  ORG_EDIT_PROJECT = 'organization/project/edit-project/{project_slug}',
  ORG_PROJECT_FAVORITE = 'organization/project/mark-favourite-project/{project_slug}',
  ORG_MANAGE_PROJECT_PERMISSIONS = 'organization/project/manage-project-permissions/{project_slug}',
  ORG_GET_PROJECT_REPORT_AUDIT = 'organization/project/get-project-report-audit/{project_slug}',
  ORG_EXPORT_PROJECT_RECORDS = "/organization/project/export-project-records/{project_slug}",
  ORG_PROJECT_DOWNLOAD_REPORT = "organization/project/download-report/{project_slug}",
  ORG_PROJECT_CLONE = "organization/project/clone/{project_slug}",
  ORG_PROJECT_DELETE = "organization/project/delete-project/{project_slug}",
  ORG_EXPORT_PROJECT_STANDARD_CODEBOOK = "organization/project/export-standard-codebook/{project_slug}",
  ORG_PROJECT_CODING_REVIEW_FILTERS = "organization/project/get-coding-review-filters/{project_slug}",
  ORG_PROJECT_CODING_REVIEW_DATA = "organization/project/get-coding-review-data/{project_slug}",
  ORG_PROJECT_RECORDS_TIMELINE_VIEW_DATA = "organization/project/get-project-timeline-records/{project_slug}",
  ORG_PROJECT_ERROR_SAMPLING_DATA = "organization/project/get-error-sampling-data/{project_slug}",
  ORG_PROJECT_ERROR_SAMPLING_EXPORT_DATA = "organization/project/get-error-sampling-export-data/{project_slug}",
  ORG_PROJECT_GET_SUGGESTIVE_TEXT_HISTORY = "organization/project/get-project-suggestive-text-history/{project_slug}",


  /** Project Question */
  ORG_GET_PROJECT_QUESTION_TYPE = "organization/filters/question-type-list",
  ORG_UPDATE_PROJECT_QUESTIONS_ORDER = "organization/project/update-question-order/{project_slug}",
  ORG_CHECK_PROJECT_QUESTION_UNIQUE_VARIABLE = "organization/project/check-unique-variable/{project_slug}",
  ORG_ADD_EDIT_PROJECT_QUESTION = "organization/project/create_edit-project-question/{project_slug}",
  ORG_DELETE_PROJECT_QUESTIONS = 'organization/project/delete-project-questions/{project_slug}',
  ORG_CHANGE_QUESTION_TYPE = 'organization/project/change-question-type/{project_slug}',

  /** Project Record */
  ORG_GET_PROJECT_RECORDS = "organization/project/get-project-records/{project_slug}",
  ORG_CREATE_RECORD = 'organization/record/create-project-record/{project_slug}',
  ORG_EDIT_RECORD = 'organization/record/edit-project-record/{project_slug}/{record_id}',
  ORG_GET_PROJECT_RECORD_DETAILS = 'organization/record/get-record-details/{project_slug}/{record_id}',
  ORG_DELETE_PROJECT_RECORD = 'organization/record/delete-project-record/{project_slug}/{record_id}',
  ORG_ADD_RECORD_SOURCE = '/organization/record/add-source/{project_slug}/{record_id}',
  ORG_UPDATE_RECORD_SOURCE_TILE = '/organization/record/update-source-title/{project_slug}/{record_id}',
  ORG_UPDATE_RECORD_SOURCE_TIMELINE = '/organization/record/update-source-timeline/{project_slug}/{record_id}',
  ORG_UPDATE_RECORD_SOURCE_TEXT = '/organization/record/update-source-text/{project_slug}/{record_id}',
  ORG_ADD_RECORD_SOURCE_CITATION = '/organization/record/add-edit-citations/{project_slug}/{record_id}',
  ORG_DELETE_RECORD_SOURCE_CITATION = '/organization/record/delete-citations/{project_slug}/{record_id}',
  ORG_RECORD_SOURCE_CITATION_QUESTIONS_LINK = '/organization/record/add-remove-questions-from-citation/{project_slug}/{record_id}',
  ORG_GET_RECORD_SOURCE_LIST = '/organization/record/get-source-list/{project_slug}/{record_id}',
  ORG_GET_CUSTOM_JURISDICTIONS = 'organization/record/get-custom-jurisdictions',
  ORG_CHECK_JURISDICTION_EXISTS = 'organization/record/check-jurisdictions-exists/{project_slug}',
  ORG_EXIST_JURISDICTION_INFO = 'organization/record/find-existing-jurisdictioninfo/{project_slug}',
  ORG_DELETE_RECORD_SOURCE = '/organization/record/delete-source/{project_slug}/{record_id}',
  ORG_DETACH_RECORD_SOURCE = '/organization/record/detach-source/{project_slug}/{record_id}',
  ORG_MARK_FINISHED_RECORD = '/organization/record/mark-finished/{project_slug}/{record_id}',
  ORG_ADD_EDIT_ANSWER = '/organization/record/answer/{project_slug}/{record_id}',
  ORG_UPLOAD_ATTACHED_FILE = '/organization/record/upload-attach-file/{project_slug}/{record_id}',
  ORG_DOWNLOAD_ATTACHED_FILE = '/organization/record/download-attach-file/{project_slug}/{record_id}',
  ORG_DELETE_ATTACHED_FILE = '/organization/record/delete-attach-file/{project_slug}/{record_id}',
  ORG_GET_RECORD_LAST_SAVED = '/organization/record/get-last-saved/{project_slug}/{record_id}',
  ORG_ASSOCIATE_CITATION_WITH_QUESTION = "/organization/record/associate-citation-with-question/{project_slug}/{record_id}",
  ORG_RECORD_AMEND_SOURCE = "/organization/record/amend-source/{project_slug}/{record_id}",
  ORG_MANAGE_RECORD_PERMISSIONS = "/organization/record/manage-users/{project_slug}/{record_id}",
  ORG_GET_RECORD_ASSOCIATED_USERS = "/organization/record/get-associated-users/{project_slug}/{record_id}",
  ORG_RECORD_AMEND_RECORD = "/organization/record/amend-record/{project_slug}/{record_id}",
  ORG_RECORD_GET_CLONED_RECORD_COUNT = "/organization/record/clone-record-count/{project_slug}/{record_id}",
  ORG_RECORD_ADD_CLONE_RECORD = "/organization/record/clone-record/{project_slug}/{record_id}",
  ORG_RECORD_RESTORE_RECORD = "/organization/record/restore-record/{project_slug}",
  ORG_RECORD_HIDE_UNHIDE_RECORD = "/organization/record/hide-unhide-record/{project_slug}/{record_id}",
  ORG_GET_RECORD_TIMELINE_DATA = "/organization/record/get-record-timeline-data/{project_slug}/{record_id}",
  ORG_TRIGGER_SUGGESTIVE_TEXT = "/organization/record/trigger-suggestive-text/{project_slug}/{record_id}",
  ORG_RECORD_GET_QUESTIONS_WITH_SUGGESTIVE_TEXT = "/organization/record/get-questions-with-suggestive-text/{project_slug}/{record_id}",
  ORG_RECORD_GET_SUGGESTIVE_TEXT_BY_QUESTION = "/organization/record/get-suggestive-text-by-question/{project_slug}/{record_id}",
  ORG_RECORD_SUGGESTIVE_TEXT_CREATE_CITATION = "/organization/record/suggestive-text-create-citation/{project_slug}/{record_id}",

  /** Publish and Preview */
  ORG_GET_PUBLISHING_SITES = "organization/project/publish/get-publishing-sites",
  ORG_CHECK_UNIQUE_URL_SLUG = "organization/project/publish/check-url-slug",
  ORG_GET_PUBLISHING_SITE_INFO = "organization/project/publish/{project_slug}/get-publish-site-info",
  ORG_SAVE_PUBLISH_STEP_1 = "organization/project/publish/{project_slug}/step-1",
  ORG_SAVE_PUBLISH_STEP_2 = "organization/project/publish/{project_slug}/step-2",
  ORG_SAVE_PUBLISH_STEP_3 = "organization/project/publish/{project_slug}/step-3",
  ORG_SAVE_PUBLISH_STEP_4 = "organization/project/publish/{project_slug}/step-4",
  ORG_PROJECT_UNPUBLISH = "organization/project/{project_slug}/unpublish",
  ORG_PROJECT_UNLINK = "organization/project/{project_slug}/unlink",
  ORG_GET_PREVIEW_DATA = "organization/project/publish/{project_slug}/get-preview-data",
  ORG_GET_LAW_DATA = "organization/project/publish/get-law-text",
  GET_PREVIEW_DATA_WITH_PASS_CODE = "project-preview/{org_key}/{site_key}/{project_slug}/details",
  GET_PREVIEW_LAW_DATA_WITH_PASS_CODE = "project-preview/{org_key}/{site_key}/{project_slug}/get-law-text",
  ORG_GET_CITATION_DATA = "organization/project/publish/get-citation-text",
  GET_PREVIEW_CITATION_DATA_WITH_PASS_CODE = "project-preview/{org_key}/{site_key}/{project_slug}/get-citation-text",

  /** Organization Group */
  ORG_GET_GROUP_LIST = "organization/group/get-group-list",
  ORG_GET_GROUP_PROJECT_LIST = "organization/group/get-project-list",
  ORG_CREATE_GROUP = "organization/group/create-group",
  ORG_EDIT_GROUP = "organization/group/edit-group",
  ORG_GET_GROUP_DETAILS = "organization/group/get-group-details",
  ORG_DELETE_GROUP = "organization/group/delete-group",
  ORG_GROUP_FAVORITE = 'organization/group/mark-favourite-group',

  /** Super Admin or Application Admin Dashboard */
  APP_GET_ORGANIZATION_LIST = "app/dashboard/get-organization-list",
  APP_APPLY_ORG_CUSTOM_SETTINGS = "app/dashboard/apply-custom-settings",
  APP_PUBLISH_API_CREATE_ORGANIZATION_TOKEN = "app/dashboard/get-publish-api-organization-token",
  APP_PUBLISH_API_FETCH_ORGANIZATION_TOKEN = "app/dashboard/fetch-publish-api-organization-token",
  APP_PUBLISH_API_UPDATE_ORGANIZATION_TOKEN = "app/dashboard/update-publish-api-organization-token",
  APP_EXPIRE_REACTIVATE_ORGANIZATION = "app/dashboard/expire-reactivate-organization",
  APP_GET_SETTINGS = "app/settings/get-app-settings",
  APP_EDIT_SETTINGS = "app/settings/edit-app-settings",
  APP_APPLY_SETTINGS_FOR_ALL = "app/settings/apply-settings-for-all",
  APP_GET_INDIVIDUAL_USER_LIST = "app/dashboard/get-individual-user-list",
  APP_ACCEPT_ORGANIZATION_SETTINGS = "app/settings/organization/approve",
  APP_REJECT_ORGANIZATION_SETTINGS = "app/settings/organization/reject",
  APP_APPLY_INDIVIDUAL_CUSTOM_SETTINGS = "app/dashboard/apply-indvl-custom-settings",
  APP_EXPIRE_INDIVIDUAL_USER = "app/dashboard/expire-individual-user",
  APP_GET_ORGANIZATION_ADDON_LIST = "app/dashboard/get-organization-addon-list",
  APP_APPLY_ORG_ADDONS = "app/dashboard/apply-organization-addons",

  /** Organization Site */
  ORG_SITE_GET_SITE_PROJECTS = "organization/site/get-site-project-list",
  ORG_SITE_GET_SITE_LIST = "organization/site/get-site-list",
  ORG_SITE_POST_CREATE_SITE = "organization/site/create-site",
  ORG_SITE_GET_SITE_DETAILS = "organization/site/get-site-details/{site_id}",
  ORG_SITE_POST_EDIT_SITE = "organization/site/edit-site",
  ORG_CHECK_SITE_UNIQUE_TITLE = "organization/site/check-unique-site",
  ORG_SITE_GET_SITE_USER_LIST = "organization/site/get-site-user-list",
  ORG_SITE_GET_SITE_PREVIEW_PASSCODE = "organization/site/get-preview-passcode",
  ORG_SITE_UPDATE_SITE_PREVIEW_PASSCODE = "organization/site/update-preview-passcode",
  ORG_SITE_POST_SITE_USER = "organization/site/add-site-user",
  ORG_SITE_DELETE_SITE_USER = "organization/site/remove-site-user",
  ORG_SITE_POST_DELETE_SITE = "organization/site/delete-site",
  ORG_SITE_GET_SITE_TOKEN = "organization/site/get-publish-api-site-token",
  ORG_IMPORTER_TOOL_UPLOAD_FILE = "organization/project/importer-tool-file-upload/{project_slug}",

  /** Organization ranking tool */
  ORG_RANKING_PROJECT_GET_QUESTION = "organization/ranking-tool/get-question-snapshot/{ranking_project_id}",
  ORG_RANKING_PROJECT_GET_RANKING_TEMPLATES = "organization/ranking-tool/get-ranking-templates",
  ORG_RANKING_PROJECT_CREATE_RANKING_TEMPLATE = "organization/ranking-tool/create-ranking-template",
  ORG_RANKING_PROJECT_EDIT_RANKING_TEMPLATE = "organization/ranking-tool/edit-ranking-template",
  ORG_RANKING_PROJECT_DELETE_RANKING_TEMPLATE = "organization/ranking-tool/delete-ranking-template",
  ORG_RANKING_PROJECT_GET_RECORD_ANSWERS = "organization/ranking-tool/get-record-answers/{ranking_project_id}/{record_id}",

  /** Importer Tool */
  ORG_PROJECT_IMPORTER_TOOL_QUESTION = "organization/project/importer-tool/get-question-data/{project_slug}",
  ORG_PROJECT_IMPORTER_TOOL_UPDATE_QUESTION = "organization/project/importer-tool/update-question-data/{project_slug}",
  ORG_PROJECT_IMPORTER_TOOL_JURISDICTION = "organization/project/importer-tool/get-jurisdiction-data/{project_slug}",
  ORG_PROJECT_IMPORTER_TOOL_UPDATE_JURISDICTION = "organization/project/importer-tool/update-jurisdiction-data/{project_slug}",
  ORG_PROJECT_IMPORTER_TOOL_DELETE_STAGED_DATA = "organization/project/importer-tool/delete-staged-data/{project_slug}",

}
