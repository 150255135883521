import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Spin, Tooltip } from "antd";
import { LeftOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useProjectDetailsStore, useProjectRecordsStore } from "../../store";
import CustomError from "modules/shared/components/CustomError";
import ErrorBoundary from "modules/shared/components/ErrorBoundary";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { usePageTitle } from "modules/shared/hooks";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import CustomFileUploader from "modules/shared/components/CustomFileUploader";
import { appConfig } from "config/config";
import { downLoadImporterTemplate } from "modules/shared/utility";
import ProjectGearAction from "../orgProjectDetails/components/ProjectGearAction";
import StatusUpdate from "./components/StatusUpdate";
import OrgStagePage from "./importerStageTable/OrgStagePage";
import ViewStateTableErrors from "./ViewStageTableErrors";
import DeleteStagingModal from "./DeleteStagingModal";

const OrgImporterDetailsPage: React.FC<{}> = () => {
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { questionCount, isLoadingProjectDetails, projectDetails, error } =
    useProjectDetailsStore();
  const { actualRecordsCount } = useProjectRecordsStore();
  usePageTitle(
    `MonQcle - ${projectDetails ? projectDetails?.title : "Projects"}`
  );
  const { hasPermissions } = useRbac();
  const supportedFileToolTip: string = "xls, xlsx, xlsm";

  if (!projectDetails) return <>Loading...</>;

  return (
    <ErrorBoundary>
      <Spin spinning={isLoadingProjectDetails}>
        {error?.code ? (
          <CustomError errorDetails={error} isAccessFromPage={true} />
        ) : (
          <Fragment>
            <div className="pageTopSection">
              <div className="backLink">
                <Link to={organizationBaseRouteUrl + "/projects"}>
                  <LeftOutlined /> Back to Projects List
                </Link>
              </div>
              {!projectDetails?.importing_status && (
                <div className="btnWrap">
                  <Tooltip
                    placement="right"
                    trigger={["hover", "focus"]}
                    title={"Download Importer Template"}
                    overlayStyle={{ maxWidth: "40%" }}
                  >
                    <Button
                      onClick={() => {
                        downLoadImporterTemplate(
                          appConfig.REACT_APP_GCP_ASSETS_BUCKET_SHARED_URL +
                            appConfig.REACT_APP_IMPORTER_TEMPLATE_URL
                        );
                      }}
                      disabled={
                        !hasPermissions(
                          [ERbacPermissions.ORG_PROJECT_IMPORTER_MANAGE],
                          {
                            project_role_id: projectDetails?.project_role_id,
                            direct_permissions:
                              projectDetails?.direct_permissions,
                          }
                        )
                      }
                      icon={<ArrowDownOutlined />}
                      type="primary"
                    >
                      Download
                    </Button>
                  </Tooltip>
                  <ProjectGearAction
                    disabled={false}
                    entityCount={{
                      questionCount:
                        questionCount ?? projectDetails?.question_count ?? 0,
                      recordCount:
                        actualRecordsCount ?? projectDetails?.record_count ?? 0,
                    }}
                  />
                  <ViewStateTableErrors/>
                </div>
              )}
              {["importing_failed", "staged", "staging_failed"].includes( projectDetails?.importing_status) && (
                <div className="btnWrap">
                  <DeleteStagingModal/>
                </div>
              )}
              {(projectDetails?.importing_status === "staged") && (
                <div className="btnWrap">
                  <ViewStateTableErrors/>
                </div>
              )}
            </div>
            <div className="pageHeadingWrap">
              <h2
                id="tab-header"
                className="ellipsisTxt"
                style={{ maxWidth: "50%" }}
              >
                <Tooltip
                  placement="right"
                  trigger={["hover"]}
                  title={projectDetails?.title ?? ""}
                  overlayStyle={{ maxWidth: "40%" }}
                >
                  <p className="ellipsisTxt">{projectDetails?.title ?? ""}</p>
                </Tooltip>
              </h2>
            </div>
            { projectDetails?.importing_status && (
              ["requested", "extracting", "transforming", "loading", "staged", "staging_failed"].includes(
                projectDetails?.importing_status
              ) ?
                (
                  projectDetails?.importing_status === "staged" ?
                    <OrgStagePage/>
                  :
                    <StatusUpdate
                      status={projectDetails?.importing_status}
                      projectId={projectDetails?.project_id ?? ""}
                      projectSlug={projectDetails?.slug ?? ""}
                      isStaging={true}
                    />
                )
                 :
                (
                  <StatusUpdate
                    status={projectDetails?.importing_status}
                    projectId={projectDetails?.project_id ?? ""}
                    projectSlug={projectDetails?.slug ?? ""}
                    isStaging={false}
                  />
                )
              )
            }
            {!projectDetails?.importing_status && (
              <Tooltip
                title={
                  !hasPermissions(
                    [ERbacPermissions.ORG_PROJECT_IMPORTER_MANAGE],
                    {
                      project_role_id: projectDetails?.project_role_id,
                      direct_permissions: projectDetails?.direct_permissions,
                    }
                  )
                    ? "You don't have permission to import"
                    : undefined
                }
                trigger={["hover", "focus"]}
              >
                <div>
                  <CustomFileUploader
                    supportedFileToolTip={supportedFileToolTip}
                    disabled={
                      !hasPermissions(
                        [ERbacPermissions.ORG_PROJECT_IMPORTER_MANAGE],
                        {
                          project_role_id: projectDetails?.project_role_id,
                          direct_permissions:
                            projectDetails?.direct_permissions,
                        }
                      )
                    }
                    projectSlug={projectDetails?.slug ?? ""}
                  ></CustomFileUploader>
                </div>
              </Tooltip>
            )}
          </Fragment>
        )}
      </Spin>
    </ErrorBoundary>
  );
};

export default OrgImporterDetailsPage;
