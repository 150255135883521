import { Avatar, Tag, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React, { Fragment, ReactNode, useState } from 'react';
import Text from 'antd/es/typography/Paragraph';
import {
  CloseCircleFilled,
  CheckCircleFilled,
  LoadingOutlined
} from '@ant-design/icons';
import { UniqueCheckStatus } from 'modules/organization/store/createNewProjectStore';
import { Buffer } from 'buffer';
import { IProjectRecordAssociatedUser, IQuestionType } from 'modules/organization/models/interface';
import { Link } from 'react-router-dom';
import { ITooltipParagraphProps } from 'models/interface';
import { colorConfigs } from 'config/commonConfig';

export const toTitleCase = (value: string) => {
  return value.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const addToolTip = (
  data: any,
  placement?: TooltipPlacement | undefined,
  cursorType: string = 'default',
  maxCharacterCount: number = 15,
  dataCount: boolean = false,
  overlayInnerStyle: Object = { textTransform: 'none' },
  overlayStyle: Object = { textTransform: 'none' }
) => {
  if (dataCount) {
    if (data) {
      if (data.length > 1) {
        return (
          <Tooltip
            overlayStyle={overlayStyle}
            overlayInnerStyle={overlayInnerStyle}
            title={`${data.map((each: any) => each)}`}
            arrowPointAtCenter={true}
            trigger={['hover','focus']}
          >
            <span style={{ cursor: cursorType }}>{`${data[0]} + ${
              data.length - 1
            } more...`}</span>
          </Tooltip>
        );
      } else {
        return data[0];
      }
    }
  } else {
    if (data.length > maxCharacterCount) {
      return (
        <Tooltip
          overlayStyle={overlayStyle}
          overlayInnerStyle={overlayInnerStyle}
          placement={placement ?? 'rightTop'}
          title={data}
          trigger={['hover','focus']}
        >
          <span style={{ cursor: cursorType }}>
            {data.slice(0, maxCharacterCount).trim()}...
          </span>
        </Tooltip>
      );
    }
    return <span style={{ cursor: cursorType }}>{data}</span>;
  }
};

export const getEncodedData = (data: {}) => {
  return btoa(JSON.stringify(data));
};

export const getDecodedData = (data: string) => {
  return JSON.parse(atob(data));
};

export const getQueryParams = (url: string) => {
  const data = url.split('?')[1];
  const value = data.split('&');
  const paramsObject: { [key: string]: any } = {};

  for (let param of value) {
    const [key, value] = param.split('=');
    paramsObject[key] = getDecodedData(value);
  }

  return paramsObject;
};

export const TooltipParagraph: React.FC<ITooltipParagraphProps> = ({
  children,
  ellipsis,
  toLink,
  tabIndex = 0,
  ...props
}) => {
  const [truncated, setTruncated] = useState(false);
  return (
    <Tooltip
      title={truncated ? children : undefined}
      trigger={["hover", "focus"]}
    >
      {toLink ? (
        <Link to={toLink} className="ellipsisTxt">
          <Text {...props} ellipsis={{ onEllipsis: setTruncated }} aria-label={children as string}>
            <>{children}</>
          </Text>
        </Link>
      ) : (
        <Text {...props} className="ellipsisTxt" ellipsis={{ onEllipsis: setTruncated }} tabIndex={tabIndex} aria-label={children as string}>
          <>{children}</>
        </Text>
      )}
    </Tooltip>
  );
};

export const numberFormatter = (number: string) => {
  return number.replace(/(.)(?=(\d{3})+$)/g, '$1,'); // NOSONAR
};

export const uniqueFeedBack = (
  onBlurCheck: UniqueCheckStatus,
  onSubmitCheck: UniqueCheckStatus,
  message?: { success: string; error: string }
) => {
  if (onBlurCheck === 'checking' || onSubmitCheck === 'checking') {
    return <LoadingOutlined />;
  } else if (onBlurCheck === 'exist' || onSubmitCheck === 'exist') {
    return (
      <Tooltip title={message?.error ?? 'Not available'} trigger={['hover','focus']}>
        <CloseCircleFilled style={{ color: 'red' }} />
      </Tooltip>
    );
  } else if (onBlurCheck === 'unique' || onSubmitCheck === 'unique') {
    return (
      <Tooltip title={message?.success ?? 'Available'} trigger={['hover','focus']}>
        <CheckCircleFilled style={{ color: 'green' }} />
      </Tooltip>
    );
  } else {
    return <span />;
  }
};

export const downloadBufferAsFile = (
  bufferData: Buffer,
  fileName: string,
  filetype: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
) => {
  const base64ExcelData = bufferData
    ? Buffer.from(bufferData).toString('base64')
    : '';
  let sampleArr = base64ToArrayBuffer(base64ExcelData);
  const blob = new Blob([sampleArr], { type: filetype });
  const downloadLink = document.createElement('a');
  const url = URL.createObjectURL(blob);
  const isSafariBrowser =
    navigator.userAgent.indexOf('Safari') !== -1 &&
    navigator.userAgent.indexOf('Chrome') === -1;
  if (isSafariBrowser) {
    // if Safari open in new window to save file with random filename.
    downloadLink.setAttribute('target', '_blank');
  }
  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', fileName);
  downloadLink.style.visibility = 'hidden';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const base64ToArrayBuffer = (base64: string) => {
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export function scrollToSpecificId(
  id: number,
  type: string,
  expandedSourceKeys: number[],
  setExpandedSourceKeys: Function,
  citationLawId: number | null = null
) {
  if (!expandedSourceKeys.includes(citationLawId ?? id)) {
    setExpandedSourceKeys([...expandedSourceKeys, citationLawId ?? id]);
  }
  setTimeout(() => {
    const element = document.getElementById(`${type}${id}`);
    if (element) {
      if (type === 'sourceLaw') {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      } else {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    } else {
      console.warn(`Element with ID "${id}" not found.`);
    }
  }, 200);
}

export const getAvatar = (
  fullName: string,
  avatarUrl?: string | null,
  localProfileImage?: string | ArrayBuffer | null
): ReactNode => {
  const nameParts = fullName.split(' ');

  const getNameInitials = () => {
    if (nameParts.length === 1) {
      const endIndex = nameParts[0].length > 1 ? 2 : 1
      return nameParts[0].slice(0, endIndex).toUpperCase();
    } else if (nameParts.length >= 2) {
      return `${nameParts[0].charAt(0)}${nameParts[nameParts.length - 1].charAt(
        0
      )}`.toUpperCase();
    } else {
      return '';
    }
  };

  return (
    <Fragment>
      {avatarUrl || localProfileImage ? (
        <Avatar
          aria-label={`Profile image of ${fullName}`}
          src={
            localProfileImage
              ? localProfileImage.toString()
              : avatarUrl
          }
          alt="Profile image"
          
        />
      ) : (
        <Avatar  aria-label={fullName}><span role="img" aria-label=''>{getNameInitials()}</span></Avatar>
      )}
    </Fragment>
  );
};

export const getFilteredOption = (questionTypeId: number, isAnswered: boolean, questionTypeList: IQuestionType[]) => {
  let questionToExclude: number[] = []
  if (isAnswered) {
    questionToExclude = [1, 2, 3, 7]
    switch (+questionTypeId) {
      case 10:
        questionToExclude.push(4);
        break;
      case 12:
        questionToExclude.push(10);
        break;
      case 8:
        questionToExclude.push(10);
        questionToExclude.push(4);
        break;
      case 4:
        questionToExclude.push(10);
        questionToExclude.push(8);
        questionToExclude.push(12);
        break;
      default:
        questionToExclude.push(10);
        questionToExclude.push(8);
        questionToExclude.push(12);
        questionToExclude.push(4);
        break;
    }
  }
  const tmpKeys = questionTypeList.filter(eachType => eachType.id !== questionTypeId && !questionToExclude.includes(eachType.id));
  return tmpKeys.map(eachType => eachType.id);
}

/**
 * Shuffles array
 * @template T 
 * @param array 
 * @returns array 
 */
export function shuffleArray<T>(array: T[]): T[] {
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
  }

  return shuffledArray;
}

export function containsString(inputValue: string, listArr: string[]) {
  // Convert input string to lowercase for case-insensitive comparison
  const lowerInputString = inputValue.toLowerCase();

  if( (lowerInputString.split('/')).length === 5 ){
    return false;
  }

  // Iterate over the wordArray
  for (let word of listArr) {
    // Convert the current word to lowercase for case-insensitive comparison
    const lowerWord = word.toLowerCase();

    // Check if the input string contains the current word
    if (lowerInputString.includes(lowerWord)) {
      // If any word is found, return true
      return true;
    }
    
  }
  
  // If none of the words are found, return false
  return false;
}

export const renderExtraTags = (tags: string[]) => {
  const omittedValues = (
      <Tooltip title={tags.join(", ")} trigger={["hover", "focus"]}>
        <Tag color={colorConfigs.DEFAULT_TAG_COLOR}>+{tags.length}</Tag>
      </Tooltip>
  );
  if( tags.length > 0 ) {
    return [ omittedValues ]
  }
}

export function findMissingPermissions(source: IProjectRecordAssociatedUser[] | null | undefined , destination: IProjectRecordAssociatedUser[]) {
  // Create a map to store permissions by user_id

  if(!source) {
    return null;
  }

  const permissionsMap = new Map();

  // Populate permissionsMap with source array data
  source.forEach(item => {
      const key = item.user_id;
      permissionsMap.set(key, item.permissions_id);
  });

  // Update permissionsMap with destination array data
  destination.forEach(item => {
      const key = item.user_id;
      if (permissionsMap.has(key)) {
          const permissions = permissionsMap.get(key);
          if(!permissions){
            return [];
          }
          item.permissions_id?.forEach(id => {
              const index = permissions.indexOf(id);
              if (index !== -1) {
                  permissions.splice(index, 1);
              }
          });
          permissionsMap.set(key, permissions);
      }
  });

  // Create the result array
  const result: any[] = [];
  permissionsMap.forEach((permissions, key) => {
      result.push({
          user_id: key,
          permissions_id: permissions
      });
  });

  return result;
}

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Successfully copied to clipboard.");
    return true;
  } catch (err) {
    console.error("Failed to copy text:", err);
    return false;
  }
};

export function scrollToHighlightedSuggestiveText(
  id: string,
  expandedSourceKeys: number[],
  setExpandedSourceKeys: Function,
  citationLawId: number
) {
  if (!expandedSourceKeys.includes(citationLawId)) {
    setExpandedSourceKeys([...expandedSourceKeys, citationLawId]);
  }
  setTimeout(() => {
    const element = document.querySelector(`[data-id='${id}']`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } else {
      console.warn(`Element with ID "${id}" not found.`);
    }
  }, 200);
};

export function formatDate(inputDate: Date | string): string {
  const date = new Date(inputDate);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) return "today";
  if (isYesterday) return "yesterday";

  // Format date as mm/dd/yyyy
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function downLoadImporterTemplate (url:string){
  const link = document.createElement("a");
  link.href = url;
  link.download = "importer_template.xlsx";
  link.click();
}