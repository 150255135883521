import { useRef, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { useProjectDetailsStore } from "modules/organization/store";
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";

const DeleteStagingModal: React.FC<{ getRecentProjects?: Function }> = () => {
  const [openModal, setOpenModal] = useState(false);
  const { projectDetails, getProjectDetails, clearProjectDetails } = useProjectDetailsStore();

  const okButtonRef = useRef<HTMLButtonElement>(null);
  const { setPromptType, setPromptText } = usePromptText();

  const headingRef = useRef<HTMLParagraphElement>(null);

  const deleteStagedData = async () => {
    const deleteStagedData = "deleteStagedData";
    let promptText = "Clearing data...";

      setPromptType("assertive");
      setPromptText(promptText);
      message.open({
        key: deleteStagedData,
        content: promptText,
        type: "loading",
        duration: 0,
      });
      setOpenModal(false);
      try {
          const result = await new ProjectImporterToolService().deleteStagedData({
            project_slug: projectDetails?.slug ?? "",
          })
          if (result.status == 200) {
            promptText = "Data cleared successfully";
            setPromptType("assertive");
            setPromptText(promptText);
            message.success(promptText);
            clearProjectDetails();
            getProjectDetails(projectDetails?.slug ?? "").catch((error) => console.log(error));
        }
      } catch (error) {
        console.log(error);
        promptText = "Failed to clear data";
        setPromptType("assertive");
        setPromptText(promptText);
        message.error(promptText);
      }
    message.destroy(deleteStagedData);
  };

  return (
    <>
      <Button
        className="btnStyle3 smlBtn"
        type="primary"
        onClick={() => setOpenModal(true)}
      >
        Clear Data
      </Button>

      <Modal
        className="confirmationModal"
        title={<>Clear Data</>}
        centered
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button key="no" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={deleteStagedData}
            ref={okButtonRef}
          >
            Delete
          </Button>,
        ]}
      >
        <div className="notificationTxtWrap">
          <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
          <div className="notificationTxt">
            <p tabIndex={-1} ref={headingRef}>Are you sure you want to clear data for <strong>{projectDetails?.title}</strong>?</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteStagingModal;