import { Button, Form, Tooltip, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { RcFile } from 'antd/es/upload';
import { ICustomFileUploaderData } from 'modules/organization/models/interface';
import { mnDate } from 'modules/shared/services';
import { dateFormat } from 'config/commonConfig';
import { ProjectListService } from 'modules/organization/services';
import { useImporterFileStore, useProjectDetailsStore } from 'modules/organization/store';
import { DeleteOutlined, FileExcelOutlined } from "@ant-design/icons";
import { addToolTip } from 'modules/shared/utility';

export const supportedMimeTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    '.xlsm'
];

interface FileUploaderProps {
    supportedFileToolTip: string,
    disabled: boolean,
    projectSlug: string
}

export const initialFileData: ICustomFileUploaderData = {
    new_file_name: null,
    new_file_data: null,
    new_file_type: null,
    old_file: null,
    deleted: false
}

const CustomFileUploader: React.FC<FileUploaderProps> = ({ supportedFileToolTip, disabled, projectSlug }) => {

    const { file, setImporterFileStore } = useImporterFileStore();
    const { projectDetails, getProjectDetails, clearProjectDetails } = useProjectDetailsStore();

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        return () => {
            setImporterFileStore(initialFileData);
        };
    }, [setImporterFileStore]);

    const formatFileName = (fileName: string, withTimestamp: boolean = true) => {
        const parts = fileName.split('.');
        if (withTimestamp) {
            return `${parts[0]}_${mnDate().format(dateFormat.fileTimestamp)}.${parts[parts.length - 1]}`;
        } else {
            const fileNamePartsWithoutExtension = parts[0].split('_')
            return `${fileNamePartsWithoutExtension.slice(0, fileNamePartsWithoutExtension.length - 1).join('_')}.${parts[parts.length - 1]}`
        }
    }

    const uploadOtherFile = async (file: RcFile) => {

        if (!supportedMimeTypes.includes(file.type)) {
            message.error("File not supported.");
            return;
        }

        const oldFile = null;
        const reader = new FileReader();
        reader.onload = async () => {
            const base64String = reader.result?.toString().split(",")[1];
            if (base64String) {
                const customFile = {
                    project_title: projectDetails?.title,
                    project_slug: projectDetails?.slug,
                    new_file_data: base64String,
                    new_file_name: formatFileName(file.name),
                    new_file_type: file.type,
                    old_file: oldFile ?? null,
                    deleted: !!oldFile
                };
                setImporterFileStore({ ...customFile })
            }
        };
        reader.readAsDataURL(file);
        return false
    };

    const onFinish = async () => {

        if (!(file.new_file_data && file.new_file_name)) {
            message.info("please upload file!");
            return
        }
        try {
            setIsLoading(true)
            const result = await new ProjectListService().uploadImporterToolFile(projectSlug, file)
            if (result.status == 200) {
                message.success("File uploaded successfully!");
                clearProjectDetails();
                getProjectDetails(projectSlug ?? "").catch((error) => console.log(error));
            }

        } catch (error) {
            message.error("Some thing went wrong!");
        } finally {
            setIsLoading(false)

        }


    };

    const deleteOtherFiles = () => {
        if (!file.new_file_name) {
            return;
        }
        setImporterFileStore(initialFileData)
    }


    return (
        <Form className="publishRightContent uploadCustomWrap" form={form} layout='vertical' onFinish={onFinish}>
            <Upload className="uploadCustom" accept={supportedMimeTypes.join(',')} beforeUpload={(file) => uploadOtherFile(file)} listType='picture-card' showUploadList={false} maxCount={1} disabled={disabled}>
                <div className="fileUploadIcon"></div>
                <div>Click or drag file to this area to upload</div>
                <small>Supported file type {supportedFileToolTip}</small>
            </Upload>
            <ul className="uploadedList customFileList">
                {file.new_file_name && file.new_file_data && <li>
                    <div className="listFile">
                        <span className="fileIcon"><FileExcelOutlined aria-hidden /></span>
                        <span className="statusTag">{addToolTip(file.new_file_name, 'top', 'default', 100)}</span>
                        <Tooltip title="Delete Import File" trigger={['hover', 'focus']} placement="right">
                            <Button className="iconOnlyBtn smallIcon" danger onClick={() => deleteOtherFiles()} icon={<DeleteOutlined />}></Button>
                        </Tooltip>
                    </div>
                </li>}
            </ul>
            <div className="content-align-end">
                <Button type="primary" htmlType="submit" loading={isLoading} disabled={disabled} >Upload</Button>
            </div>
        </Form>
    )
}

export default CustomFileUploader;