import { pageSizeConfigs } from 'config/commonConfig';
import { create } from 'zustand';
import { onSaveJurisdictions, onSaveQuestions } from '../orgProject/importerToolView/importerStageTable/components/tables/common';
import { getJsonDifferences } from '../orgProject/importerToolView/importerStageTable/components/StageStepOne';

export type TImportingKey = typeof STAGE_STEPS[keyof typeof STAGE_STEPS]
export const STAGE_STEPS = {
    STEP_1: 1,
    STEP_2: 2
} as const;

interface IImporterStagingStep {
    key: TImportingKey;
    name: string;
    backBtnLabel?: string;
    continueBtnLabel: string;
    getBtnLabel?: (isPublicationPublic: boolean) => any;
    hideStepsControl?: boolean;
}

export const importingSteps: { [K in TImportingKey]: IImporterStagingStep } = {
    [STAGE_STEPS.STEP_1]: {
        key: STAGE_STEPS.STEP_1,
        name: "Questions",
        backBtnLabel: "Cancel",
        continueBtnLabel: "Validate & Proceed",
    },
    [STAGE_STEPS.STEP_2]: {
        key: STAGE_STEPS.STEP_2,
        name: "Jurisdictions",
        backBtnLabel: "Back",
        continueBtnLabel: "Import to Project",
    }
}

interface IProjectImportingState {
    currentStep: IImporterStagingStep;
    finishStep: number;
    editingCell: {rowIndex: number; dataIndex: string;} | null;
    showLoader: boolean;
    dataSubmission: boolean;
    mappingOptions: any;
    headerList: any[];
    questionOriginalDataList: any[];
    dataList: any[];
    questionOriginalColumnMapping: any;
    questionColumnMapping: any;
    columnMapping: any;
    dataCount: number;
    currentPageSize: number;
    currentPage: number;
    validationGlobalErrors: { [key: string]: { page: number, row: number, message: string, dynamic?: boolean } };
    showEditRecordModal: boolean;
    showDeleteRecordModal: boolean;
    jurisdictionInfo: any;
    excelHeaderList: any[];

    setEditingCell: (value: {rowIndex: number; dataIndex: string;} | null) => void;
    setShowLoader: (value: boolean) => void;
    setMappingOptions: (header: any[]) => void;
    setColumnMapping: (key: string, value: string, isQuestion?: boolean) => void;
    setMappingInitValue: (value: Object, isQuestion?: boolean) => void; 
    setQuestionOriginalDataList: (value: any) => void;
    setDataList: (rowIndex: number, dataIndex: string, value: any) => void;
    setJurisdictionData: (rowIndex: number, value: any) => void;
    setDataCount: (count: number) => void;
    setCurrentPageSize: (pageSize: number) => void;
    setCurrentPage: (page: number) => void;
    setDataSubmission: (value: boolean) => void;
    setFinishStep: (value: number) => void;
    setCurrentStep: (step: TImportingKey) => void;
    setValidationGlobalErrors: (key: string, action: string, value: any) => void;
    setShowEditRecordModal: (value: boolean) => void;
    setShowDeleteRecordModal: (value: boolean) => void;
    deleteJurisdictionRow: (projectDetails: any) => void; 
    setJurisdictionInfo: (value: any) => void;
    triggerSaveImporter: (name: string, projectDetails: any, userDetails: any) => void;
    refreshDataList: (header: any[], xcelHeader: any[], data: any[]) => void;
    resetDataState: () => void;
    resetState: () => void;
}

const useStore = create<IProjectImportingState>((set, get) => ({
    currentStep: importingSteps[1],
    finishStep: 0,
    editingCell: null,
    showLoader: true,
    dataSubmission: false,
    mappingOptions: [],
    headerList: [],
    questionOriginalDataList: [],
    dataList: [],
    questionOriginalColumnMapping: null,
    questionColumnMapping: {
        order: 'order', 
        question: 'question', 
        variable_name: 'variable_name', 
        question_type: 'question_type',
        possible_options: 'possible_options',
    },
    columnMapping: {
        jurisdiction: 'jurisdiction',
        jurisdiction_types: 'jurisdiction_types',
        country: 'country',
        series: 'series',
        effective_from: 'effective_from',
        through_to: 'through_to'
    },
    dataCount: 0,
    currentPageSize: pageSizeConfigs.OPTIONS[0].value,
    currentPage: 1,
    validationGlobalErrors: {},
    showEditRecordModal: false,
    showDeleteRecordModal: false,
    jurisdictionInfo: {
        key: null,
        rowIndex: 0,
        place_id: null,
        jurisdiction_id: null,
        country: null,
        jurisdiction_name: null,
        place: {
            type: "state",
            jurisdiction_value: null,
        },
    },
    excelHeaderList: [],

    setMappingOptions: (headers) => {
        set(() => ({
            mappingOptions: headers
        }));
    },
    setColumnMapping: (key, value, isQuestion=false) => {
        if(Boolean(isQuestion)){
            set((state) => ({
                questionColumnMapping: {
                    ...state.questionColumnMapping,
                    [key]: value
                }
            }));
        }else{
            set((state) => ({
                columnMapping: {
                    ...state.columnMapping,
                    [key]: value
                }
            }));
        }
    },
    setMappingInitValue: (value, isQuestion = false) => {
        set(() => {
            if(isQuestion) {
                return { questionColumnMapping: value, questionOriginalColumnMapping: value }
            } else {
                return { columnMapping: value }
            }
        })
    },
    setQuestionOriginalDataList: (value) => {
        set(() => ({
            questionOriginalDataList: value
        }))
    },
    setDataList: (rowIndex, dataIndex, value) => {
        set((state) => {
            const actualRowIndex = ((state.currentPage - 1) * state.currentPageSize ) + rowIndex
            const updatedDataList = [...state.dataList];
            if (updatedDataList[actualRowIndex]) {
                updatedDataList[actualRowIndex] = {
                    ...updatedDataList[actualRowIndex],
                    [dataIndex]: (!Array.isArray(value) && value?.trim() === '') ? null : value
                };
            }
            return { dataList: updatedDataList };
        });
    },
    setJurisdictionData: (rowIndex, value) => {
        set((state) => {
            const actualRowIndex = ((state.currentPage - 1) * state.currentPageSize ) + rowIndex
            const updatedDataList = [...state.dataList];
            if (updatedDataList[actualRowIndex]) {
                updatedDataList[actualRowIndex] = {
                    ...updatedDataList[actualRowIndex],
                    [state.columnMapping['jurisdiction']]: value.jurisdiction,
                    jurisdiction_id: value.jurisdiction_id,
                    place_id: value.place_id,
                    jurisdiction_types: value.jurisdiction_types,
                    country: value.country
                };
            }
            return { dataList: updatedDataList };
        });
    },
    setEditingCell: (value) => {
        set(() => ({
            editingCell: value
        }));
    },
    setShowLoader: (value) => {
        set(() => ({
            showLoader: value
        }));
    },
    setDataCount: (count) => {
        set(() => ({
            dataCount: count,
        }));
    },
    setCurrentPageSize: (pageSize) => {
        set(() => ({
            currentPageSize: pageSize,
            currentPage: 1
        }));
    },
    setCurrentPage: (page) => {
        set(() => ({
            currentPage: page
        }));
    },
    setDataSubmission: (value: boolean) => {
        set(() => ({
            dataSubmission: value
        }))
    },
    setFinishStep: (value: number) => {
        set(() => ({
            finishStep: value
        }))
    },
    setCurrentStep: (step) =>  {    
        set(() => ({
            currentStep: importingSteps[step]
        }));
    },
    setValidationGlobalErrors: (key, action, value) => {
        set((state) => {
            if(action === 'Add') {
                return { 
                    validationGlobalErrors: {
                        ...state.validationGlobalErrors,
                        [key]: value
                    } 
                };
            } else {
                const tempObj = { ...state.validationGlobalErrors }
                delete tempObj[key]
                return { validationGlobalErrors: tempObj };
            }
        });
    },
    setShowEditRecordModal: (value) => {
        set(() => ({
            showEditRecordModal: value
        }))
    },
    setShowDeleteRecordModal: (value) => {
        set(() => ({
            showDeleteRecordModal: value
        }))
    },
    deleteJurisdictionRow: (projectDetails) => {
        set((state) => {
            const updatedData = state.dataList.filter((item: any) => item.key !== state.jurisdictionInfo.key);
            onSaveJurisdictions(
                updatedData,
                state.columnMapping,
                state.excelHeaderList,
                state.validationGlobalErrors,
                projectDetails?.slug
            );
            return {
                dataList: updatedData,
                dataCount: updatedData.length
            }
        })
    },
    setJurisdictionInfo: (value) => {
        set(() => ({
            jurisdictionInfo: value
        }))
    },
    triggerSaveImporter: async (name, projectDetails, userDetails) => {
        const getter = get();
        if(name === 'Questions') {
            await onSaveQuestions(
                getter.questionOriginalDataList,
                getter.dataList,
                getter.questionColumnMapping,
                getter.excelHeaderList,
                getJsonDifferences,
                userDetails,
                getter.validationGlobalErrors,
                projectDetails?.slug
            );
        } else if(name === 'Jurisdictions') {
            await onSaveJurisdictions(
                getter.dataList,
                getter.columnMapping,
                getter.excelHeaderList,
                getter.validationGlobalErrors,
                projectDetails?.slug
            );
        }
    },
    refreshDataList: async (header, xcelHeader, data) => {
        set(() => ({
            headerList: header.length > 0 ? [...header] : [],
            dataList: data.length > 0 ? [...data] : [],
            excelHeaderList: xcelHeader.length > 0 ? [...xcelHeader] : [],
            dataCount: data.length,
            showLoader: false,
        }));
    },
    resetDataState: () => {
        set(() => ({
            editingCell: null,
            showLoader: true,
            dataSubmission: false,
            mappingOptions: [],
            headerList: [],
            questionOriginalDataList: [],
            dataList: [],
            questionOriginalColumnMapping: null,
            questionColumnMapping: {
                order: 'order', 
                question: 'question', 
                variable_name: 'variable_name', 
                question_type: 'question_type',
                possible_options: 'possible_options',
            },
            columnMapping: {
                jurisdiction: 'jurisdiction',
                jurisdiction_types: 'jurisdiction_types',
                country: 'country',
                series: 'series',
                effective_from: 'effective_from',
                through_to: 'through_to'
            },
            dataCount: 0,
            currentPageSize: pageSizeConfigs.OPTIONS[0].value,
            currentPage: 1,
            validationGlobalErrors: {},
            showEditRecordModal: false,
            jurisdictionInfo: {
                key: null,
                place_id: null,
                jurisdiction_id: null,
                country: null,
                jurisdiction_name: null,
                place: {
                    type: "state",
                    jurisdiction_value: null,
                },
            }
        }))
    },
    resetState: () => {
        set(() => ({
            currentStep: importingSteps[1],
            finishStep: 0,
            editingCell: null,
            showLoader: true,
            dataSubmission: false,
            mappingOptions: [],
            headerList: [],
            questionOriginalDataList: [],
            dataList: [],
            questionOriginalColumnMapping: null,
            questionColumnMapping: {
                order: 'order', 
                question: 'question', 
                variable_name: 'variable_name', 
                question_type: 'question_type',
                possible_options: 'possible_options',
            },
            columnMapping: {
                jurisdiction: 'jurisdiction',
                jurisdiction_types: 'jurisdiction_types',
                country: 'country',
                series: 'series',
                effective_from: 'effective_from',
                through_to: 'through_to'
            },
            dataCount: 0,
            currentPageSize: pageSizeConfigs.OPTIONS[0].value,
            currentPage: 1,
            validationGlobalErrors: {},
            showEditRecordModal: false,
            jurisdictionInfo: {
                key: null,
                rowIndex: 0,
                place_id: null,
                jurisdiction_id: null,
                country: null,
                jurisdiction_name: null,
                place: {
                    type: "state",
                    jurisdiction_value: null,
                },
            }
        }))
    }
}));

export const useImporterToolStore = useStore;
