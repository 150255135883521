import React from 'react';
import { Skeleton, Table } from 'antd';

interface TableSkeletonProps {
  rows?: number;
  columns?: number;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ rows = 5, columns = 4 }) => {
  const getColumns = () => {
    return Array(columns).fill(null).map((_, index) => ({
      title: <Skeleton.Input style={{ width: 100 }} active size="small" />,
      dataIndex: `column-${index}`,
      key: `column-${index}`,
      render: () => <Skeleton.Input style={{ width: '100%' }} active size="small" />,
    }));
  };

  const getData = () => {
    return Array(rows).fill(null).map((_, index) => ({
      key: `row-${index}`,
      ...Object.fromEntries(
        Array(columns).fill(null).map((_, colIndex) => [`column-${colIndex}`, null])
      ),
    }));
  };

  return (
    <Table
      dataSource={getData()}
      columns={getColumns()}
      pagination={false}
    />
  );
};

export default TableSkeleton;
