import { message } from "antd";
import StageStepContainer from "./StageStepContainer";
import {
  STAGE_STEPS,
  useImporterToolStore,
} from "modules/organization/store/importerToolStore";
import Questions from "./tables/Questions";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";
import { useProjectDetailsStore } from "modules/organization/store";
import { useRbac } from "auth/rbac/rbac";
import { onSaveQuestions } from "./tables/common";

export function getJsonDifferences(oldArray: any, newArray: any) {
  const differences: any = [];
  const newMap = new Map(newArray.map((item: any) => [item.key, item]));

  oldArray.forEach((oldItem: any) => {
      const newItem = newMap.get(oldItem.key);
      if (!newItem) {
          // Item with this key was removed
          differences.push({
              type: "deleted",
              key: oldItem.key,
              value: oldItem,
          });
      } else if (!areObjectsEqual(oldItem, newItem)) {
          // Item has changed
          differences.push({
              type: "changed",
              key: oldItem.key,
              value: newItem,
          });
      }
  });

  return differences;
}

function areObjectsEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
      if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
          return false;
      }
  }

  return true;
}

const StageStepOne = () => {
  const { projectDetails } = useProjectDetailsStore();

  const { userDetails } = useRbac();

  const { 
    questionOriginalDataList,
    dataList,
    questionOriginalColumnMapping,
    questionColumnMapping, 
    excelHeaderList, 
    validationGlobalErrors,
    setCurrentStep, 
    setDataSubmission, 
    setFinishStep, 
    resetDataState 
  } = useImporterToolStore();


  const onSave = () => {
    onSaveQuestions(
      questionOriginalDataList,
      dataList,
      questionColumnMapping,
      excelHeaderList,
      getJsonDifferences,
      userDetails,
      validationGlobalErrors,
      projectDetails?.slug,
      true
    );
  }

  const onFinish = async () => {
    setDataSubmission(true);
    try {
      const updatedOriginalDataList = questionOriginalDataList.map(item => {
        const { original_possible_options, ...rest } = item;
        return rest;
      });
      
      const updatedDataList = dataList.map(item => {
        const { original_possible_options, ...rest } = item;
        let extracted = rest;
        if(extracted[questionColumnMapping.question_type] === 'BIN'){
          extracted[questionColumnMapping.possible_options] = ['Yes', 'No'];
        } else if(['TEXT', 'CURRENCY', 'DATE', 'NUMBER'].includes(extracted[questionColumnMapping.question_type])) {
          extracted[questionColumnMapping.possible_options] = null;
        }
        return extracted;
      });

      const finalQuestionData = {
        header: excelHeaderList,
        data: updatedDataList
      }

      const differences = getJsonDifferences(updatedOriginalDataList, updatedDataList);

      if(differences.length > 0 || (JSON.stringify(questionOriginalColumnMapping) !== JSON.stringify(questionColumnMapping))) {
        const payload = {
          user_id: userDetails?.id,
          org_id: userDetails?.org_id,
          question_data: finalQuestionData,
          column_mapping: questionColumnMapping,
          question_diff: differences
        }  
        await new ProjectImporterToolService().updateImporterToolQuestion(projectDetails?.slug ?? "", payload);
        message.success({
          key: "updateQuestionData",
          content: "Question data updated successfully!",
          duration: 2,
        });
      }
      setDataSubmission(false);
      resetDataState();
      setFinishStep(STAGE_STEPS.STEP_1);
      setCurrentStep(STAGE_STEPS.STEP_2);
    } catch (error) {
      console.error(error);
      setDataSubmission(false);
      message.error({
        key: "updateQuestionData",
        content: "Some thing went wrong!",
        duration: 2,
      });
    }
  };

  return (
    <div className="importerContainer">
      <div className="publishRightContent">
        <StageStepContainer onFinish={onFinish} onSave={onSave}>
          <Questions componentName="Questions"/>
        </StageStepContainer>
      </div>
    </div>
  );
};

export default StageStepOne;
