import { Spin } from 'antd';
import { useProjectDetailsStore } from 'modules/organization/store';
import PageLoader from 'modules/shared/components/PageLoader';
import { usePageTitle } from 'modules/shared/hooks';
import { Fragment, ReactNode, useEffect } from 'react';
import ErrorBoundary from 'modules/shared/components/ErrorBoundary';
import StageStepStatus from './components/StageStepStatus';
import StageStepOne from './components/StageStepOne';
import StageStepTwo from './components/StageStepTwo';
import CustomError from 'modules/shared/components/CustomError';
import { useImporterToolStore } from 'modules/organization/store/importerToolStore';

const OrgProjectPublishPage = () => {
    const { projectDetails, isLoadingProjectDetails, error } = useProjectDetailsStore();
    const { currentStep, dataSubmission, resetState } = useImporterToolStore();
  
    usePageTitle(
      `MonQcle - ${
        projectDetails
          ? projectDetails?.title + ' - ImporterFlow'
          : 'Importer Flow'
      }`
    );

    const stageStep: { [key: number]: ReactNode } = {
      1: <StageStepOne />,
      2: <StageStepTwo />,
    }

    useEffect(() => {

      return () => {
        resetState()
      }
    }, [resetState])
    
  
    return (
      <Fragment>
        {error ? (
            <div>
                <CustomError errorDetails={error} isAccessFromPage={true}/>
            </div>
        ) : (
          <ErrorBoundary>
            <Fragment>
              {isLoadingProjectDetails ? (
                <PageLoader />
              ) : (
                <Spin spinning={false}>
                  {projectDetails && (
                      <ErrorBoundary>
                        <Spin spinning={dataSubmission}>
                          <div className='publishWrapper'>
                              {!currentStep.hideStepsControl && <StageStepStatus />}
                              {stageStep[currentStep.key]}
                          </div>
                        </Spin>
                      </ErrorBoundary>
                  )}
                </Spin>
              )}
            </Fragment>
          </ErrorBoundary>
        )}
      </Fragment>
    );
}

export default OrgProjectPublishPage;
