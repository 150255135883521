export const pageSizeConfigs = {
  OPTIONS: [
    {
      value: 10,
      label: '10'
    },
    {
      value: 25,
      label: '25'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 75,
      label: '75'
    },
    {
      value: 100,
      label: '100'
    }
  ],
  DEFAULT_PAGE_SIZE: 25
};

export const colorConfigs = {
  DEFAULT_TAG_COLOR: '#5d7996'
};

export const dateFormat = {
  default: "MM/DD/YYYY",
  defaultTimeStamp: "MM/DD/YYYY, hh:mm A",
  database: "YYYY-MM-DD",
  tableDateTime: "MMM DD, YYYY, hh:mm A",
  tableDate: "MMM DD, YYYY",
  fileTimestamp: "YYYY-MM-DD-HH-mm-ss",
}