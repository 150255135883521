import { RoutesEnum } from "models/enums";
import { URLService } from "./url.service";
import { IApiBaseResponse } from "models/interface";
import { ApiService } from "modules/shared/services";

export class ProjectImporterToolService {

    public async getImporterToolQuestion(project_slug: string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_IMPORTER_TOOL_QUESTION);
        return (await new ApiService().privateClient()).get<IApiBaseResponse>(updatedURL)
    }

    public async updateImporterToolQuestion(project_slug: string, payload: any) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_IMPORTER_TOOL_UPDATE_QUESTION);
        return (await new ApiService().privateClient()).post<IApiBaseResponse>(updatedURL, payload)
    }

    public async getImporterToolJurisdiction(project_slug: string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_IMPORTER_TOOL_JURISDICTION );
        return (await new ApiService().privateClient()).get<IApiBaseResponse>(updatedURL, {  })
    }

    public async updateImporterToolJurisdiction(project_slug: string, payload: any) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_IMPORTER_TOOL_UPDATE_JURISDICTION);
        return (await new ApiService().privateClient()).post<IApiBaseResponse>(updatedURL, payload)
    }

    public async deleteStagedData({project_slug}: {project_slug: string}) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_IMPORTER_TOOL_DELETE_STAGED_DATA);
        return (await new ApiService().privateClient()).delete<IApiBaseResponse<{project_slug: string}>>(updatedURL);
    }
}
