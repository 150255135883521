import { useEffect, useRef } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
import { useProjectDetailsStore } from "modules/organization/store";

const DeleteRecordModal = () => {
  const { 
    showDeleteRecordModal, 
    jurisdictionInfo, 
    setShowDeleteRecordModal, 
    deleteJurisdictionRow, 
  } = useImporterToolStore();

  const { projectDetails } = useProjectDetailsStore();


  const okButtonRef = useRef<HTMLButtonElement>(null);
  const headingRef = useRef<HTMLParagraphElement>(null);

  const doDeleteARecord = async () => {
    setShowDeleteRecordModal(false);
    deleteJurisdictionRow(projectDetails);
    message.success("Record deleted successfully")

  };

  useEffect(() => {
    setTimeout(() => {
      headingRef.current?.focus();
      headingRef.current?.click();
    }, 1000)

  }, [])

  return (
    <Modal
      className="confirmationModal"
      title={<>Delete Record</>}
      centered
      open={showDeleteRecordModal}
      onCancel={() => setShowDeleteRecordModal(false)}
      footer={[
        <Button key="yes" onClick={() => setShowDeleteRecordModal(false)}>
          Cancel
        </Button>,
        <Button
          key="no"
          type="primary"
          onClick={() => doDeleteARecord()}
          ref={okButtonRef}
        >
          Delete Record
        </Button>,
      ]}
    >
      <div className="notificationTxtWrap">
        <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
        <div className="notificationTxt">
          <p tabIndex={-1} ref={headingRef} >Are you sure you want to delete <strong>{jurisdictionInfo?.jurisdiction_name}</strong>?</p>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRecordModal;