import { Button } from "antd";
import {
  TImportingKey,
  useImporterToolStore,
} from "modules/organization/store/importerToolStore";
import Paginator from "modules/shared/components/Paginator";
import { Fragment, isValidElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useOrganizationRoutes } from "routes/organizationRoute";

const StageStepContainer: React.FC<{
  children: ReactNode;
  onFinish: () => void;
  onSave: () => void;
}> = ({ children, onFinish, onSave }) => {
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const {
    editingCell,
    currentStep,
    setCurrentStep,
    dataSubmission,
    currentPageSize,
    setCurrentPageSize,
    dataCount,
    setCurrentPage,
    currentPage,
    resetDataState,
    validationGlobalErrors
  } = useImporterToolStore();

  const navigate = useNavigate();

  const onCancel = () => {
    if (currentStep.key - 1 < 1) {
      return navigate(
        `${organizationBaseRouteUrl}/projects`
      );
    }
    resetDataState();
    return setCurrentStep((currentStep.key - 1) as TImportingKey);
  };

  return (
    <Fragment>
      {children}
      <div className="rowBlk">
        <div className="colBlk12 gap10">
          <Paginator
            disabled={!(editingCell === null)}
            currentPageSize={currentPageSize}
            count={dataCount}
            setCurrentPageSize={setCurrentPageSize}
            setCurrentPage={setCurrentPage}
            getShowingDataString={() => {}}
            paginationRender={(_, type, originalElement) => {
              if (type === "prev") {
                return <span>Previous</span>;
              }
              if (type === "next") {
                return <span>Next</span>;
              }
              return originalElement;
            }}
            currentPage={currentPage}
            showChanger={false}
            triggerName={isValidElement(children) ? children.props.componentName : null}
          />
        </div>
        <div className="colBlk12">
          <div className="btnWrap">
            {currentStep?.backBtnLabel && (
              <Button onClick={onCancel}>{currentStep?.backBtnLabel}</Button>
            )}
            <Button
              type="primary"
              onClick={() => onSave()}
              className="importerToolSaveData"
            >
              Save
            </Button>
            <Button
              type="primary"
              onClick={() => onFinish()}
              disabled={ (Object.keys(validationGlobalErrors).length > 0) || dataSubmission}
            >
              {currentStep?.continueBtnLabel}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StageStepContainer;
