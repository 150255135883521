import { GoogleMap, Marker } from "@react-google-maps/api";
import { Spin } from "antd";
import React from "react";

const containerStyle = {
  height: "450px",
  backgroundRepeat: "no-repeat",
};

const GoogleMapViewer: React.FC<{
  geoData: any;
  loadingMap: boolean;
}> = ({ geoData, loadingMap }) => {

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    map.data.addGeoJson(geoData);
    let bounds = new google.maps.LatLngBounds();

    map.data.forEach(function(feature) {
      let geo = feature.getGeometry();
      geo?.forEachLatLng(function(LatLng) {
        bounds.extend(LatLng);
      });
    });
    map.fitBounds(bounds);

  }, [geoData]);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    console.log("Unmounted");
  }, []);

  return (
    <Spin spinning={loadingMap}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: +geoData?.properties?.lat, lng: +geoData?.properties?.lon }}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{scrollwheel: false, streetViewControl: false, draggable: true, }}
      >
        {/* <Marker position={{ lat: +geoData.lat, lng: +geoData.lon }} /> */}
        {geoData?.geometry?.type !== "Point" && (
          <Marker position={{ lat: +geoData?.properties?.lat, lng: +geoData?.properties?.lon }} />
        )}
      </GoogleMap>
    </Spin>
  );
};

export default React.memo(GoogleMapViewer);
