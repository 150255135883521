import React, { useEffect, useState } from "react";
import { Button, Drawer, message } from "antd";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";

function formatKey(key: string, dynamic: boolean): string {
    // Split the key by underscores, capitalize each part, and join with spaces
    if(dynamic) return (key.split('_').slice(0, -1).join('_')).toUpperCase();

    return key
        .split('_') // Split by underscore
        .slice(0, -1) // Remove the last part (e.g., '57')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize first letter
        .join(' '); // Join with spaces
}

const ViewStateTableErrors: React.FC<{}> = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { validationGlobalErrors, showLoader, currentStep } = useImporterToolStore();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (!(Object.keys(validationGlobalErrors)?.length)) {
      setOpenDrawer(false);
    }
  }, [validationGlobalErrors]);

  function blinkElement(id: string, isHover: boolean) {
    const element = document.getElementById(id);
    if(element){
      if(isHover) {
        element.classList.add('blinkingBtn');
      } else {
        element.classList.remove('blinkingBtn');
      }
    }
  }

  function scrollToElementById(id: string, type: string, page: number) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center', 
        inline: type !== 'overlap' ? 'center' : undefined // Conditionally add 'center' or undefined
      });
    } else {
      message.info(`Please head over to page ${page}`);
    }
  }

  return (
    <React.Fragment>
      {!showLoader &&
        validationGlobalErrors &&
        Object.keys(validationGlobalErrors)?.length > 0 && (
          <Button
            className="btnStyle3 smlBtn blinkingBtn"
            type="primary"
            onClick={showDrawer}
          >
            View Errors
          </Button>
        )}

      <Drawer
        title={<h2>{currentStep?.name?.slice(0, -1)} Errors</h2>}
        placement={"right"}
        className="customDrawer"
        closable={false}
        onClose={onClose}
        open={openDrawer}
        mask={false}
        extra={
          <Button onClick={onClose} className="closeBlk">
            Close
          </Button>
        }
      >
        <ul className="drawerItemList suggestiveHistorylist">
          {Object.entries(validationGlobalErrors)?.sort(([key_a, a], [key_b, b]) => {
            // Check if key_a is 'overlap'
            if (key_a === 'overlap' && Array.isArray(a)) {
              // Sort 'overlap' entries by the first item's page and then by row
              return a.map(item => item.page).sort((pageA, pageB) => pageA - pageB)[0] - b.page || 
                    a.map(item => item.row).sort((rowA, rowB) => rowA - rowB)[0] - b.row; // Compare with the first item's page and row
            }

            if (key_b === 'overlap' && Array.isArray(b)) {
              // Sort 'overlap' entries by the first item's page and then by row
              return b.map(item => item.page).sort((pageA, pageB) => pageA - pageB)[0] - a.page || 
                    b.map(item => item.row).sort((rowA, rowB) => rowA - rowB)[0] - a.row; // Compare with the first item's page and row
            }
            // First sort by page
            if (a.page !== b.page) {
              return a.page - b.page;
            }
            // If pages are equal, sort by row
            return a.row - b.row;
          })?.map(([key, value]) => {
            if(key === 'overlap' && Array.isArray(value) ) {
                return (
                  <ul key={key}>
                      {
                        value.map((each: any) => {
                          return (
                              <li key={each.id} onClick={() => scrollToElementById(`${each.id}`, key, each.page)} onMouseOver={() => blinkElement(key, true)} onMouseOut={() => blinkElement(key, false)} style={{cursor: "pointer"}}>
                                  <div className="shContent">
                                    <div className="itemListHeading">
                                        <span className="suggestiveTitle">Duplicate Row</span>
                                    </div>
                                    <div className="itemDate">
                                        Page: {each.page} | Row: {each.row}
                                    </div>
                                  </div>
                              </li>
                          )
                        })
                      }
                  </ul>
                )
            } else {
                return (
                  <li key={key} onClick={() => scrollToElementById(key, key, value.page)} style={{cursor: "pointer"}} onMouseOver={() => blinkElement(key, true)} onMouseOut={() => blinkElement(key, false)}>
                      <div className="shContent">
                          <div className="itemListHeading">
                              <span className="suggestiveTitle">{value.message}</span>
                          </div>
                          <div className="itemDate">
                              Page: {value.page} | Row: {value.row} | Column: { formatKey(key, value.dynamic === true)}
                          </div>
                      </div>
                  </li>
                )
            }
          })}
        </ul>
      </Drawer>
    </React.Fragment>
  );
};

export default ViewStateTableErrors;
