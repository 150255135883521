import { Input, Select, Table, TableColumnsType } from "antd";
import { debounce } from "lodash";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";
import { useProjectDetailsStore } from "modules/organization/store";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { validateQuestions } from "./validateQuestions";
import { ImporterMapperIcon } from "modules/shared/components/CustomAppIcons";
import { findKey, getKeyByValue } from "./common";
import TableSkeleton from "./TableSkeleton";


const Questions:React.FC<{componentName: string}> = () => {
  const { projectDetails } = useProjectDetailsStore();

  const {
    showLoader,
    dataList,
    questionColumnMapping,
    currentPage,
    currentPageSize,
    validationGlobalErrors,
    mappingOptions,
    editingCell,
    setFinishStep,
    setQuestionOriginalDataList,
    setDataList,
    refreshDataList,
    setValidationGlobalErrors,
    setMappingOptions,
    setEditingCell,
    setMappingInitValue,
    setColumnMapping,
    resetDataState } = useImporterToolStore();


  const errorRefs = useRef([] as any);
  const editCellRef = useRef<any>(null);

  const setErrorRef = useCallback(
    (id: string, ref: any) => {
      if (ref) {
        errorRefs.current[id] = ref;
      }
    },
    []
  );

  const paginatedData = useMemo(() => {
    return dataList.slice((currentPage - 1) * currentPageSize, currentPage * currentPageSize);
  }, [dataList, currentPage, currentPageSize]);

  const handleChange = useCallback((rowIndex: number, dataIndex: string) => {
    return debounce((newValue) => {
      setDataList(rowIndex, questionColumnMapping[dataIndex], newValue);
    }, 300);
  }, [questionColumnMapping]);

  const handleCellClick = (rowIndex: number, dataIndex: string) => {
    // Logic to switch to editable mode
    setEditingCell({ rowIndex, dataIndex }); // Assuming you have a state to track the editing cell
    setTimeout(() => {
      editCellRef.current?.focus();
    }, 100);
  };

  const renderNonEditField = (value: any, dataIndex: string) => {
    if (dataIndex === 'possible_options') {
      return Array.isArray(value) ? value.join(" || ") : value;
    } else {
      return value;
    }
  }

  const renderReadOnly = (dataIndex: string, rowIndex: number, value: any) => {
    return (
      <div
        tabIndex={0}
        ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}
        onClick={() => handleCellClick(rowIndex, dataIndex)}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleCellClick(rowIndex, dataIndex);
        }}
        style={{ cursor: "pointer", padding: "6.5px 11px", height: "100%" }}
      >
        <div
          style={{
            overflow: 'hidden', // Hide overflow
            textOverflow: 'ellipsis', // Add ellipsis
            display: '-webkit-box', // For webkit browsers
            WebkitBoxOrient: 'vertical', // Set box orientation
            overflowY: 'hidden', // Hide vertical overflow
            WebkitLineClamp: 7, // Limit to 7 lines
          }}
        >
          {value?.length > 0 ? renderNonEditField(value, dataIndex) : <span style={{ color: '#797979' }}>No Data</span>}
        </div>
      </div>
    );
  }

  const renderFunction = useCallback(
    (
      value: any,
      record: any,
      rowIndex: number,
      dataIndex: string
    ) => {
      if (editingCell?.rowIndex === rowIndex &&
        editingCell?.dataIndex === dataIndex) {
        if (dataIndex === getKeyByValue(questionColumnMapping['question_type'], questionColumnMapping)) {
          const options = ['BIN', 'CAT-ME', 'CAT-CA', 'TEXT', 'NUMBER', 'DATE', 'CURRENCY'];
          return (
            <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
              <Select
                key={Date.now()}
                bordered={false}
                style={{ width: "100%", height: "100%" }}
                defaultValue={value ?? undefined}
                placeholder="Select option"
                allowClear
                options={options.map(
                  (item: any) => ({
                    value: item,
                    label: item,
                  })
                )}
                onChange={(val) => {
                  if (val !== value) {
                    // Check if value has changed
                    handleChange(rowIndex, dataIndex)(val);
                  }
                  setTimeout(() => {
                    return setEditingCell(null);
                  },150);
                }}
              />
            </div>
          )
        } else if (dataIndex === getKeyByValue(questionColumnMapping['order'], questionColumnMapping)) {
          return (
            <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
              <Input
                key={Date.now()}
                ref={editCellRef}
                type="text"
                defaultValue={value}
                className="importerInputs"
                placeholder={`Enter ${dataIndex}`}
                onKeyDown={(e) => {
                  const char = e.key;
                  // Allow only numbers, dots, and control keys
                  if (!/^[0-9.]$/.test(char) && e.key !== "Backspace" && e.key !== "Tab") {
                    e.preventDefault();
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value !== value) {
                    // Check if value has changed
                    handleChange(rowIndex, dataIndex)(e.target.value.trim());
                  }
                  setTimeout(() => {
                    return setEditingCell(null);
                  },150);
                }}
              />
            </div>
          );
        } else if (dataIndex === getKeyByValue(questionColumnMapping['question'], questionColumnMapping)) {
          return (
            <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
              <Input.TextArea
                key={Date.now()}
                ref={editCellRef}
                defaultValue={value}
                className="importerInputs"
                placeholder={`Enter ${dataIndex}`}
                onBlur={(e) => {
                  if (e.target.value !== value) {
                    // Check if value has changed
                    handleChange(rowIndex, dataIndex)(e.target.value.trim());
                  }
                  setTimeout(() => {
                    return setEditingCell(null);
                  },150);
                }}
                allowClear
              />
            </div>
          )
        } else if (dataIndex === getKeyByValue(questionColumnMapping['variable_name'], questionColumnMapping)) {
          return (
            <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
              <Input
                key={Date.now()}
                ref={editCellRef}
                defaultValue={value}
                className="importerInputs"
                placeholder={`Enter ${dataIndex}`}
                onBlur={(e) => {
                  if (e.target.value !== value) {
                    // Check if value has changed
                    handleChange(rowIndex, dataIndex)(e.target.value.trim());
                  }
                  setTimeout(() => {
                    return setEditingCell(null);
                  },150);
                }}
                allowClear
              />
            </div>
          );
        }
      }

      if (dataIndex === getKeyByValue(questionColumnMapping['possible_options'], questionColumnMapping)) {
        if (!record[questionColumnMapping['question_type']]) return null;

        if (['TEXT', 'NUMBER', 'DATE', 'CURRENCY'].includes(record[questionColumnMapping['question_type']])) {
          return null;
        }

        if (record[questionColumnMapping['question_type']] === 'BIN') {
          return (editingCell?.rowIndex === rowIndex &&
            editingCell?.dataIndex === dataIndex) ?
            (
              <Select
                key={Date.now()}
                ref={editCellRef}
                className="selectDisable"
                bordered={false}
                mode="multiple"
                value={['Yes', 'No']}
                disabled
                suffixIcon={null}
              />
            ) : renderReadOnly(dataIndex, rowIndex, ['Yes', 'No']);
        }

        if (`${record[questionColumnMapping["possible_options"]]}` === "undefined") {
          return null;
        }

        if (["CAT-CA", "CAT-ME"].includes(record[questionColumnMapping['question_type']])) {
          return (editingCell?.rowIndex === rowIndex &&
            editingCell?.dataIndex === dataIndex) ?
            (
              <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
                <Select
                  key={Date.now()}
                  ref={editCellRef}
                  bordered={false}
                  style={{ width: "100%", height: "100%" }}
                  value={Array.isArray(record[questionColumnMapping["possible_options"]]) ? record[questionColumnMapping["possible_options"]] : (record[questionColumnMapping["possible_options"]] !== null ? [record[questionColumnMapping["possible_options"]]] : undefined)}
                  mode={
                    (record[questionColumnMapping['question_type']] === "CAT-CA" || record[questionColumnMapping['question_type']] === "CAT-ME")
                      ? "tags"
                      : undefined
                  }
                  placeholder="Select option"
                  options={(Array.isArray(record.original_possible_options) ? record.original_possible_options : (record.original_possible_options !== null ? [record.original_possible_options] : null))?.map(
                    (item: any) => ({
                      value: item,
                      label: item,
                    })
                  )}
                  onChange={(val) => {
                    if (val !== value) {
                      // Check if value has changed
                      handleChange(rowIndex, dataIndex)(val);
                    }
                    setTimeout(() => {
                      return setEditingCell(null);
                    },150);
                  }}
                  allowClear
                />
              </div>
            ) : renderReadOnly(dataIndex, rowIndex, Array.isArray(record[questionColumnMapping["possible_options"]]) ? record[questionColumnMapping["possible_options"]] : (record[questionColumnMapping["possible_options"]] !== null ? [record[questionColumnMapping["possible_options"]]] : undefined));
        }
      }

      // Default to read-only text
      return (
        <div
          tabIndex={0}
          ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}
          onClick={() => handleCellClick(rowIndex, dataIndex)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleCellClick(rowIndex, dataIndex);
          }}
          style={{ cursor: "pointer", padding: "6.5px 11px", height: "100%" }}
        >
          <div
            style={{
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Add ellipsis
              display: '-webkit-box', // For webkit browsers
              WebkitBoxOrient: 'vertical', // Set box orientation
              overflowY: 'hidden', // Hide vertical overflow
              WebkitLineClamp: 7, // Limit to 7 lines
            }}
          >
            {value?.length > 0 ? renderNonEditField(value, dataIndex) : <span style={{ color: '#797979' }}>No Data</span>}
          </div>
        </div>
      );
    }, [handleChange, editingCell]
  );


  const renderTitleFunction = (title: string, dataIndex: string) => {
    return <div className="displayFlex flexAlignCenter flexJSpaceB">
      <div className="importerHeaderTitle">{title}</div>
      {!mappingOptions?.some((each: any) => each.value === dataIndex) &&
        <>
          <div style={{ padding: "2px 5px 0 9px" }}>
            <ImporterMapperIcon />
          </div>
          <Select
            key={dataIndex}
            value={questionColumnMapping[dataIndex] === dataIndex ? null : questionColumnMapping[dataIndex]}
            bordered={false}
            placeholder="Select option"
            options={mappingOptions}
            onChange={(value) => {
              setColumnMapping(dataIndex, value, true);
            }}
            style={{ maxWidth: "150px" }}
          />
        </>
      }
    </div>
  }


  const columns: TableColumnsType<any> = [
    {
      title: renderTitleFunction("Order", "order"),
      dataIndex: questionColumnMapping["order"],
      key: "order",
      width: 215,
      fixed: 'left',
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "order"
        ),
    },
    {
      title: renderTitleFunction("Variable Name", "variable_name"),
      dataIndex: questionColumnMapping["variable_name"],
      key: "variable_name",
      width: 300,
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "variable_name"
        ),
    },
    {
      title: renderTitleFunction("Question", "question"),
      dataIndex: questionColumnMapping["question"],
      key: "question",
      width: 350,
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "question"
        ),
    },
    {
      title: renderTitleFunction("Question Type", "question_type"),
      dataIndex: questionColumnMapping["question_type"],
      key: "question_type",
      width: 300,
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "question_type"
        ),
    },
    {
      title: renderTitleFunction("Possible Options", "possible_options"),
      dataIndex: questionColumnMapping["possible_options"],
      key: "possible_options",
      width: 400,
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "possible_options"
        ),
    },
  ];

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        const result = await new ProjectImporterToolService().getImporterToolQuestion(projectDetails?.slug ?? "");
        const { questionData } = result?.data.data || {};
        const { question_data, config_data, finished_step } = questionData;
        const { header, data } = question_data;

        const columnMapping = findKey(config_data, 'question_mapping')
        if (columnMapping) {
          setMappingInitValue(columnMapping, true)
        }

        setFinishStep(finished_step);

        setMappingOptions(header?.map((each: any) => {
          return {
            label: each.title,
            value: each.dataIndex
          }
        }));
        setQuestionOriginalDataList(data);
        refreshDataList(columns, header, data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuestionData();
    return () => {
      resetDataState();
    }
  }, []);

  useEffect(() => {
    if (dataList.length > 0) {
      validateQuestions({
        dataList,
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        questionColumnMapping,
        setValidationGlobalErrors,
      });
    }
  }, [dataList, currentPage, questionColumnMapping]);

  useEffect(() => {
    dataList.forEach((item: any) => {
      item.original_possible_options = item[questionColumnMapping["possible_options"]];
    });

  }, [questionColumnMapping])

  if (!projectDetails) return <>Loading...</>;

  return (
    <>
      {
        showLoader ?
          <TableSkeleton rows={10} columns={10} /> :
          <Table
            loading={showLoader}
            columns={columns}
            dataSource={paginatedData as any}
            bordered
            size="small"
            pagination={false}
            scroll={{ x: "max-content", y: "calc(100vh - 364px)" }}
          // tableLayout="auto"
          />
      }
    </>
  );
};

export default Questions;