import { IHttpError } from 'models/interface';
import { IFilteredJurisdictionData, IFlattenQuestion, IJurisdictionData, IPreviewControls, IPreviewDetails, IProfileViewFilter, IProjectQuestionTree, IQuestion, IQuestionAnswer } from '../models/interface';
import { create } from "zustand";
import { ProjectPublishService } from '../services';
interface IPreviewWithPassCodeConfig {
  preview_org_name: string | null;
  preview_site_key: string | null;
  preview_project_slug: string | null;
  pass_code?: string;
};

interface IProjectPreviewState {
  jurisdictionData: IJurisdictionData[] | null;
  totalRecordCount: number;
  questionList: IQuestion[] | null;
  dataFetchError: IHttpError | null;
  dataLoader: boolean;
  flatQuestionTree: IFlattenQuestion[];
  filteredData: null | IFilteredJurisdictionData[];
  filterControls: IPreviewControls;
  validDates: null | { start: string; end: string; };
  validAllDates: Date[];
  profileViewFilter: IProfileViewFilter;
  previewWithPassCodeConfig: IPreviewWithPassCodeConfig;
  resetProjectPreviewState: () => void;
  setPreviewData: (previewDetails: IPreviewDetails) => void;
  getPreviewData: (projectSlug: string, pubishId?: number) => Promise<void>;
  setFilteredData: (data: IFilteredJurisdictionData[]) => void;
  updateFilterControls: (data: IPreviewControls) => void;
  getColorScale: () => { key: string, displayText: string, fillColor: string }[];
  setProfileViewFilter: (data: IProfileViewFilter) => void;
  getCurrentAnswers: (jurisdiction_id: number, current_selected_record: number) => IQuestionAnswer[];
  getFilteredDataForProfileView: () => IFilteredJurisdictionData[];
  getLegendValues: () => { count: number, displayText: string, fillColor: string }[];
  getJurisdictionLawIds: (jurisdiction_id: number, current_selected_record: number) => number[];
  setPreviewWithPassCodeConfig: (data: IPreviewWithPassCodeConfig) => void;
}

const useStore = create<IProjectPreviewState>((set, get) => ({
  jurisdictionData: null,
  totalRecordCount: 0,
  questionList: null,
  dataFetchError: null,
  dataLoader: false,
  flatQuestionTree: [],
  filteredData: null,
  filterControls: {
    selected_date: null,
    filter: {
      type: 'explore',
      exploreQuestionData: null, //{question_id: 574, selected_option: null}, // 591 583 596
      filterQuestionData: null, //[{question_id: 591 , selected_options: [530]}, {question_id: 595 , selected_options: [527]}]
    }
  },
  validDates: null,
  validAllDates: [],
  profileViewFilter: {
    selected_jurisdiction: [],
    sort_key: 'jurisdiction',
    sort_order: 'asc',
    expanded_citations: null
  },
  previewWithPassCodeConfig: {
    preview_org_name: null,
    preview_site_key: null,
    preview_project_slug: null
  },
  resetProjectPreviewState: () => {
    set(() => ({
      jurisdictionData: null,
      questionList: null,
      flatQuestionTree: [],
      dataFetchError: null,
      dataLoader: false,
      filteredData: null,
      validDates: null,
      validAllDates: [],
      filterControls: {
        selected_date: null,
        filter: {
          type: 'explore',
          exploreQuestionData: null,
          filterQuestionData: null
        }
      },
      profileViewFilter: {
        selected_jurisdiction: [],
        sort_key: 'jurisdiction',
        sort_order: 'asc',
        expanded_citations: null
      },
      totalRecordCount: 0,
      previewWithPassCodeConfig: {
        preview_org_name: null,
        preview_site_key: null,
        preview_project_slug: null
      },
    }))
  },

  setPreviewData: (previewDetails: IPreviewDetails) => {
    const { jurisdiction_data, question_list, valid_dates } = previewDetails;

    const getAllDates = (startDate: string, endDate: string) => {
      const dateArray = [];
      let currentDate = new Date(startDate);
      const stopDate = new Date(endDate);

      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
      }

      return dateArray;
    }
    const _projectQuestionTree = makeQuestionTree(question_list ?? [], 0);
    const _flatQuestionTree = questionTreeToFlattenQuestionList(_projectQuestionTree);

    let totalRecordCount = 0;
    jurisdiction_data?.forEach(each => {
      const records = each?.records ?? [];
      totalRecordCount = totalRecordCount + records.length;
    })

    set((state) => ({
      jurisdictionData: jurisdiction_data ? [...jurisdiction_data] : null,
      totalRecordCount: totalRecordCount,
      questionList: question_list ? [...question_list] : null,
      flatQuestionTree: _flatQuestionTree.filter((eachQ) => eachQ.is_selected),
      validDates: {
        start: valid_dates.start.toString(),
        end: valid_dates.end.toString(),
      },
      filterControls: { ...state.filterControls, selected_date: valid_dates.end.toString() },
      dataLoader: false,
      dataFetchError: null,
      validAllDates: getAllDates(valid_dates.start.toString(), valid_dates.end.toString())
    }));

  },

  getPreviewData: async (projectSlug, publishId) => {
    try {
      set(() => ({
        dataLoader: true,
      }))
      const result = await new ProjectPublishService().getPreviewData(projectSlug, publishId );
      get().setPreviewData(result.data.data.previewDetails);
    } catch (error: any) {
      set(() => ({ dataLoader: false, dataFetchError: error }));
      console.error(error);
    }
  },
  setFilteredData: (data) => {
    set(() => ({ filteredData: data }))
  },
  updateFilterControls: (data) => {
    set(() => ({ filterControls: data }))
  },
  getColorScale: () => {
    const filterControls = get().filterControls.filter;
    const allQuestionList = get().questionList;

    let options: { key: string, displayText: string, fillColor: string }[] = [
      {
        key: 'all',
        displayText: filterControls.filterQuestionData?.length ? 'Meets Criteria' : 'Has Data' ,
        fillColor: '#8BC34A'
      }
    ]

    const colorCodesOfAll = ['#972B38'];
    const colorCodesOfMutuallyExclusive = ["#012840", "#339a9f", "#2c1dff", "#04bfbf", "#8c1c03", "#5c0002", "#ab47bc", "#f2b705", "#B6696A", "#96ca2d", "#168039", "#5E0227", "#FFA6C9", "#6ae593", "#1b9a45", "#e8d193", "#be5959", "#db9c42", "#8e922b"];
    const colorCodesOfBinary = ['#3791C1', '#ECCB7B'];
    const colorCodesOfCheckAll = ['#3182BD'];
    const colorCodesOfOpenEnded = ['#AB47BC'];

    if (filterControls.type === 'explore' && filterControls.exploreQuestionData) {
      const filteredQuestion = allQuestionList?.find(each => each.question_id === filterControls.exploreQuestionData?.question_id)
      let allQuestionOptions = filteredQuestion?.options ?? [];

      if (filterControls.exploreQuestionData?.selected_option) {
        allQuestionOptions = allQuestionOptions.filter(each => each.response_id === filterControls.exploreQuestionData?.selected_option)
      }

      let colorArr = colorCodesOfAll;
      if (filteredQuestion?.question_type_id === 8 || filteredQuestion?.question_type_id === 12) {
        //** Categorical - mutually exclusive && Categorical - weighted mutually exclusive */
        colorArr = colorCodesOfMutuallyExclusive;
      }
      else if (filteredQuestion?.question_type_id === 10) {
        //** Binary - mutually exclusive */
        colorArr = colorCodesOfBinary;
      }
      else if (filteredQuestion?.question_type_id === 4) {
        //** Binary - Categorical - check all that apply */
        colorArr = colorCodesOfCheckAll;
      }
      else {
        colorArr = colorCodesOfOpenEnded;
      }

      options = allQuestionOptions.map((each, index) => {
        return {
          key: each.response_id.toString(),
          displayText: each.response_value ?? '',
          fillColor: colorArr[index] ?? colorArr[0]
        }
      })
    }

    return options;
  },
  setProfileViewFilter: (data) => {
    set(() => ({ profileViewFilter: data }));
  },
  getCurrentAnswers: (jurisdiction_id, current_selected_record) => {
    const currentJurisdiction =
      get().jurisdictionData?.find(
        (eachJud) => eachJud.jurisdiction_id === jurisdiction_id
      ) ?? null;
    if (!currentJurisdiction?.records) {
      return [];
    }
    return (
      currentJurisdiction.records.find(
        (eachRecord) => eachRecord.record_id === current_selected_record
      )?.question_answers ?? []
    );
  },
  getFilteredDataForProfileView: () => {
    const filteredData = get().filteredData;
    const { selected_jurisdiction, sort_key, sort_order } =
      get().profileViewFilter;
    if (!filteredData || filteredData.length < 1) {
      return [];
    }
    const updatedJurisdiction = filteredData.map(eachJud => eachJud.jurisdiction_id);
    const isDifferent = selected_jurisdiction.some(judId => !updatedJurisdiction.includes(judId));
    if (isDifferent) {
      set(() => ({
        profileViewFilter: {
          ...get().profileViewFilter,
          selected_jurisdiction: selected_jurisdiction.filter(judId => updatedJurisdiction.includes(judId))
        }
      }))
      return [];
    }

    const filteredJurisdiction =
      selected_jurisdiction.length > 0
        ? [
          ...filteredData.filter((eachJud) =>
            selected_jurisdiction.includes(eachJud.jurisdiction_id)
          )
        ]
        : [...filteredData];

    if (sort_key === 'jurisdiction') {
      filteredJurisdiction.sort((a, b) => sort_order === 'asc' ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()))
    }

    return filteredJurisdiction;
  },
  getLegendValues: () => {
    const colorScales = get().getColorScale();
    const filteredData = get().filteredData;
    const legendValues = colorScales.map(({ displayText, fillColor }) => {
      let count = 0;
      filteredData?.forEach(eachJud => {
        eachJud.records.forEach(eachRecord => { if (eachRecord.fill_color === fillColor) { count++; } })
      });
      return { fillColor, displayText, count }
    });
    return legendValues.sort((a, b) => b.count - a.count);
  },
  getJurisdictionLawIds: (jurisdiction_id, current_selected_record) => {
    const currentJurisdiction =
      get().jurisdictionData?.find(
        (eachJud) => eachJud.jurisdiction_id === jurisdiction_id
      ) ?? null;
    if (!currentJurisdiction?.records) {
      return [];
    }
    const currentRecord = currentJurisdiction.records.find(eachRecord => eachRecord.record_id === current_selected_record);

    const lawIds: number[] = [];
    currentRecord?.question_answers.forEach(eachAnswer => {
      if (eachAnswer.citations) {
        eachAnswer.citations.forEach(({ law_id }) => !lawIds.includes(law_id) && lawIds.push(law_id));
      }
    });

    return lawIds;
  },
  setPreviewWithPassCodeConfig: (data) => {
    set(() => ({
      previewWithPassCodeConfig: data
    }))
  }
}));

const makeQuestionTree = (
  items: IQuestion[],
  id: number,
  link: string = "parent_question_id",
  layer: number = 1,
  layer_string: string = ""
): IFlattenQuestion[] => {
  return items
    .filter((item) => item.parent_question_id === id)
    .sort((a, b) => a.question_order - b.question_order)
    .map((item, index) => ({
      key: item.question_id,
      title: item.question,
      data: { ...item },
      layer: layer,
      parent_id: item.parent_question_id,
      is_selected: item.is_selected,
      layer_string: layer_string !== "" ? `${layer_string}.${index + 1}` : `${index + 1}`,
      children: makeQuestionTree(
        items,
        item.question_id,
        "parent_question_id",
        layer + 1,
        layer_string !== "" ? `${layer_string}.${index + 1}` : `${index + 1}`
      ),
    }));
};

const questionTreeToFlattenQuestionList = (items: any[]): IFlattenQuestion[] => {
  const flattened: any[] = [];

  function flattenNode(node: IProjectQuestionTree): void {
    flattened.push(node);

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        flattenNode(child);
      }
    }
  }

  for (const node of items) {
    flattenNode(node);
  }

  return flattened;
}

export const useProjectPreviewStore = useStore;
export const useMakeQuestionTreePreview = makeQuestionTree;
export const doQuestionTreeToFlattenQuestionListPreview = questionTreeToFlattenQuestionList;
