import { dateFormat } from "config/commonConfig";
import { mnDate } from "modules/shared/services";

const validateJurisdictionNames = (
  item: any,
  globalIndex: number,
  dataObj: any
) => {
  const {
    currentPage,
    currentPageSize,
    columnMapping,
    errorRefs,
    setValidationGlobalErrors,
  } = dataObj;
  const index = globalIndex - (currentPage - 1) * currentPageSize;
  const page = Math.floor(globalIndex / currentPageSize) + 1;
  const row = (globalIndex % currentPageSize) + 1;

  if( !item.place_id ) {
    if (errorRefs?.current[`jurisdiction_${index}`]) {
      errorRefs.current[`jurisdiction_${index}`].style.border = '1px solid red';
      errorRefs.current[`jurisdiction_${index}`].id = `jurisdiction_${globalIndex}`;
    }
    setValidationGlobalErrors(`jurisdiction_${globalIndex}`, 'Add', { page, row, message: `Jurisdiction ${item[columnMapping['jurisdiction']]} is not verified` })
  } else {
    if(errorRefs?.current[`jurisdiction_${index}`]) {
      errorRefs.current[`jurisdiction_${index}`].style.border = '1px solid white';
    }
    setValidationGlobalErrors(`jurisdiction_${globalIndex}`, 'Delete', '')
  }
};

const validateSeries = (item: any, globalIndex: number, dataObj: any) => {
  const {
    currentPage,
    currentPageSize,
    columnMapping,
    errorRefs,
    setValidationGlobalErrors,
  } = dataObj;
  const index = globalIndex - (currentPage - 1) * currentPageSize;
  const page = Math.floor(globalIndex / currentPageSize) + 1;
  const row = (globalIndex % currentPageSize) + 1;

  const isEmpty =  item[columnMapping['series']] ? item[columnMapping['series']]?.length < 1 : true;
              
  if(isEmpty) {
    if (errorRefs?.current[`series_${index}`]) {
      errorRefs.current[`series_${index}`].style.border = '1px solid red';
      errorRefs.current[`series_${index}`].id = `series_${globalIndex}`;
    }
    setValidationGlobalErrors(`series_${globalIndex}`, 'Add', { page, row, message: "Series title is mandatory" });
  } else {
    if(errorRefs?.current[`series_${index}`]) {
      errorRefs.current[`series_${index}`].style.border = '1px solid white';
    }
    setValidationGlobalErrors(`series_${globalIndex}`, 'Delete', '');
  }
};

const validateEffectiveAndThroughDate = (item: any, globalIndex: number, dataObj: any) => {
  const {
    currentPage,
    currentPageSize,
    columnMapping,
    errorRefs,
    setValidationGlobalErrors,
  } = dataObj;
  const index = globalIndex - ((currentPage - 1) * currentPageSize);
  const page = Math.floor(globalIndex / currentPageSize) + 1;
  const row = (globalIndex % currentPageSize) + 1;

  if( item[columnMapping['effective_from']] ? item[columnMapping['effective_from']]?.length < 1 : true ) {
    if (errorRefs?.current[`effective_from_${index}`]) {
      errorRefs.current[`effective_from_${index}`].style.border = '1px solid red';
      errorRefs.current[`effective_from_${index}`].id = `effective_from_${globalIndex}`;
    }
    setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Add', { page, row, message: 'Effective date is mandatory' });
  } else if ( mnDate(item[columnMapping['effective_from']], false).format(dateFormat.default) === 'Invalid Date' ) {
    if (errorRefs?.current[`effective_from_${index}`]) {
      errorRefs.current[`effective_from_${index}`].style.border = '1px solid red';
      errorRefs.current[`effective_from_${index}`].id = `effective_from_${globalIndex}`;
    }
    setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Add', { page, row, message: 'Effective date is invalid' });
  } else {
    if(errorRefs?.current[`effective_from_${index}`]) {
      errorRefs.current[`effective_from_${index}`].style.border = '1px solid white';
    }
    setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Delete', '');
  }
  if( item[columnMapping['through_to']] ? item[columnMapping['through_to']]?.length < 1 : true  ) {
    if (errorRefs?.current[`through_to_${index}`]) {
      errorRefs.current[`through_to_${index}`].style.border = '1px solid red';
      errorRefs.current[`through_to_${index}`].id = `through_to_${globalIndex}`;
    }
    setValidationGlobalErrors(`through_to_${globalIndex}`, 'Add', { page, row, message: 'Through date is mandatory' });
  } else if ( mnDate(item[columnMapping['through_to']], false).format(dateFormat.default) === 'Invalid Date' ) {
    if (errorRefs?.current[`through_to_${index}`]) {
      errorRefs.current[`through_to_${index}`].style.border = '1px solid red';
      errorRefs.current[`through_to_${index}`].id = `through_to_${globalIndex}`;
    }
    setValidationGlobalErrors(`through_to_${globalIndex}`, 'Add', { page, row, message: 'Through date is invalid' });
  } else {
    if(errorRefs?.current[`through_to_${index}`]) {
      errorRefs.current[`through_to_${index}`].style.border = '1px solid white';
    }
    setValidationGlobalErrors(`through_to_${globalIndex}`, 'Delete', '');
  }
  if((item[columnMapping['effective_from']]?.length > 0) && (item[columnMapping['through_to']]?.length > 0)){
    if(mnDate(item[columnMapping['effective_from']], false).format(dateFormat.default) !== 'Invalid Date' && mnDate(item[columnMapping['through_to']], false).format(dateFormat.default) !== 'Invalid Date'){
      if( (mnDate(item[columnMapping['effective_from']], false).date).isAfter(mnDate(item[columnMapping['through_to']], false).date) ) {
        if(errorRefs?.current[`effective_from_${index}`] && errorRefs?.current[`through_to_${index}`]) {
          errorRefs.current[`effective_from_${index}`].style.border = '1px solid red';
          errorRefs.current[`through_to_${index}`].style.border = '1px solid red';
          errorRefs.current[`effective_from_${index}`].id = `effective_from_${globalIndex}`;
          errorRefs.current[`through_to_${index}`].id = `through_to_${globalIndex}`;
        }
        setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Add', { page, row, message: 'Effective date > Through date' });
        setValidationGlobalErrors(`through_to_${globalIndex}`, 'Add', { page, row, message: 'Through date < Effective date' });
      } else {
        if(errorRefs?.current[`effective_from_${index}`] && errorRefs?.current[`through_to_${index}`]) {
          errorRefs.current[`effective_from_${index}`].style.border = '1px solid white';
          errorRefs.current[`through_to_${index}`].style.border = '1px solid white';
        }
        setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Delete', '');
        setValidationGlobalErrors(`through_to_${globalIndex}`, 'Delete', '');
      }
    }
  }
};

function findDuplicateIndices(array: any[], keys: any[], columnMapping: any): number[] {

  const combinations = new Map(); // Map to store combinations and their indices

  for (let index = 0; index < array.length; index++) {
    // Generate a unique string by combining the values of the specified keys
    const normalizedValues = keys.map((key) => {
      const value =
        key === columnMapping["effective_from"] || key === columnMapping["through_to"]
          ? mnDate(array[index][key], false).format(dateFormat.default)
          : array[index][key]; // Normalize date format
      return `${key}:${value}`;
    });

    const combination = normalizedValues.join("|");

    if (!combinations.has(combination)) {
      combinations.set(combination, []); // Initialize an array to store indices
    }
    combinations.get(combination).push(index); // Add the current index to the combination
  }

  // Collect all duplicate indices into a 1D array
  const duplicateIndices: number[] = [];
  combinations.forEach((indices) => {
    if (indices.length > 1) {
      duplicateIndices.push(...indices); // Add all indices to the result if it's a duplicate
    }
  });

  return duplicateIndices;
}

const validateRowsForDuplicate = (dataObj: any) => {
  const {
    dataList,
    currentPage,
    currentPageSize,
    columnMapping,
    rowRefs,
    setValidationGlobalErrors,
  } = dataObj;

  const values = findDuplicateIndices(dataList, [
    columnMapping["jurisdiction"],
    columnMapping["jurisdiction_types"],
    columnMapping["country"],
    columnMapping["series"],
    columnMapping["effective_from"],
    columnMapping["through_to"],
  ], columnMapping);

  dataList.forEach((_: any, index: number) => {
    const rowElement = rowRefs.current[index];
    if (rowElement) {
      rowElement.classList.remove("blinkInvalidRow"); // Example of interaction
    }
  });
  values.forEach((each) => {
    const rowElement =
      rowRefs.current[each - (currentPage - 1) * currentPageSize];
    if (rowElement) {
      rowElement.classList.add("blinkInvalidRow"); // Example of interaction
      rowElement.id = `${each}`;
    }
  });

  const overlappings = values.map((globalIndex) => {
    const page = Math.floor(globalIndex / currentPageSize) + 1;
    const row = (globalIndex % currentPageSize) + 1;
    return {
      id: globalIndex,
      page,
      row,
    };
  });
  setValidationGlobalErrors(
    `overlap`,
    values.length > 0 ? "Add" : "Delete",
    values.length > 0 ? overlappings : ""
  );
};

const validateCloseEndedAnswers = (item: any, globalIndex: number, dataObj: any) => {
  const {
    currentPage,
    currentPageSize,
    errorRefs,
    setValidationGlobalErrors,
  } = dataObj;
  const index = globalIndex - (currentPage - 1) * currentPageSize;
  const page = Math.floor(globalIndex / currentPageSize) + 1;
  const row = (globalIndex % currentPageSize) + 1;

  const allKeys = Object.keys(item);
  const allValues = Object.values(item)

  allKeys.forEach((each, keyIndex) => {
    if(!each.startsWith('options')){
      return;
    }
    const questionVar: string = each.replace("options_", "");
    const answer = item[`${questionVar}`];

    if(Array.isArray(answer)) {
      if(!answer.every((item: string) => [...allValues[keyIndex] as any].includes(item)) && !(answer === null || answer === undefined)){
        if (errorRefs?.current[`${questionVar}_${index}`]) {
          errorRefs.current[`${questionVar}_${index}`].style.border = '1px solid red';
          errorRefs.current[`${questionVar}_${index}`].id = `${questionVar}_${globalIndex}`;
        }
        setValidationGlobalErrors(`${questionVar}_${globalIndex}`, 'Add', { page, row, message: `${questionVar.toUpperCase()} have invalid coded responses`, dynamic: true });
      } else {
        if(errorRefs?.current[`${questionVar}_${index}`]) {
          errorRefs.current[`${questionVar}_${index}`].style.border = '1px solid white';
        }
        setValidationGlobalErrors(`${questionVar}_${globalIndex}`, 'Delete', '');
      }
    } else {
      if(![...allValues[keyIndex] as any].includes(answer) && !(answer === null || answer === undefined || answer === "")){
        if (errorRefs?.current[`${questionVar}_${index}`]) {
          errorRefs.current[`${questionVar}_${index}`].style.border = '1px solid red';
          errorRefs.current[`${questionVar}_${index}`].id = `${questionVar}_${globalIndex}`;
        }
        setValidationGlobalErrors(`${questionVar}_${globalIndex}`, 'Add', { page, row, message: `${questionVar.toUpperCase()} have invalid coded response`, dynamic: true });
      } else {
        if(errorRefs?.current[`${questionVar}_${index}`]) {
          errorRefs.current[`${questionVar}_${index}`].style.border = '1px solid white';
        }
        setValidationGlobalErrors(`${questionVar}_${globalIndex}`, 'Delete', '');
      }
    }
  })
};

const validateOpenEndedAnswers = (item: any, globalIndex: number, dataObj: any) => {
  const {
    currentPage,
    currentPageSize,
    errorRefs,
    setValidationGlobalErrors,
  } = dataObj;
  const index = globalIndex - (currentPage - 1) * currentPageSize;
  const page = Math.floor(globalIndex / currentPageSize) + 1;
  const row = (globalIndex % currentPageSize) + 1;

  const allKeys = Object.keys(item);

  const isNumber = ((text: any) => !(`${+text}` === 'NaN'));

  allKeys.forEach((each, keyIndex) => {
    if(!each.startsWith('type') || (each.startsWith('type') && ['CAT-ME', 'CAT-CA', 'BIN'].includes(item[each]))){
      return;
    }
    const questionVar: string = each.replace("type_", "");
    const answer = item[`${questionVar}`];
    const type= item[each];

    if(type === 'DATE' && !(answer === null || answer === undefined || answer === "") && (mnDate(answer, false).format(dateFormat.default) === 'Invalid Date')) {
      if (errorRefs?.current[`${questionVar}_${index}`]) {
        errorRefs.current[`${questionVar}_${index}`].style.border = '1px solid red';
        errorRefs.current[`${questionVar}_${index}`].id = `${questionVar}_${globalIndex}`;
      }
      setValidationGlobalErrors(`${questionVar}_${globalIndex}`, 'Add', { page, row, message: `${questionVar.toUpperCase()} have invalid coded date response`, dynamic: true });
    } else if((type === 'NUMBER' || type === 'CURRENCY') && !(answer === null || answer === undefined || answer === "") && !isNumber(answer)) {
      if (errorRefs?.current[`${questionVar}_${index}`]) {
        errorRefs.current[`${questionVar}_${index}`].style.border = '1px solid red';
        errorRefs.current[`${questionVar}_${index}`].id = `${questionVar}_${globalIndex}`;
      }
      setValidationGlobalErrors(`${questionVar}_${globalIndex}`, 'Add', { page, row, message: `${questionVar.toUpperCase()} have invalid coded ${type === 'NUMBER' ? 'number' : 'currency'} response`, dynamic: true });
    } else {
      if(errorRefs?.current[`${questionVar}_${index}`]) {
        errorRefs.current[`${questionVar}_${index}`].style.border = '1px solid white';
      }
      setValidationGlobalErrors(`${questionVar}_${globalIndex}`, 'Delete', '');
    }
  })
};

export const validateJurisdictions = (dataObj: any) => {
  const { dataList } = dataObj;
  dataList.forEach((item: any, globalIndex: number) => {
    validateJurisdictionNames(item, globalIndex, dataObj);
    validateSeries(item, globalIndex, dataObj);
    validateEffectiveAndThroughDate(item, globalIndex, dataObj);
    validateRowsForDuplicate(dataObj);
    validateCloseEndedAnswers(item, globalIndex, dataObj);
    validateOpenEndedAnswers(item, globalIndex, dataObj);
  });
};
