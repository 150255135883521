import React, { useCallback, useEffect, useState } from "react";
import { Pagination, PaginationProps, Select } from "antd";
import { pageSizeConfigs } from "config/commonConfig";
import { usePromptText } from "./accessibility/PromptStore";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
import { useProjectDetailsStore } from "modules/organization/store/projectDetailsStore";
import { useRbac } from "auth/rbac/rbac";

const Paginator: React.FC<{
  disabled?: boolean;
  currentPageSize: number;
  count: number;
  setCurrentPageSize: Function;
  setCurrentPage: Function;
  getShowingDataString: Function;
  paginationRender: PaginationProps["itemRender"];
  currentPage: number;
  showChanger?: boolean;
  triggerName?: string | null;
}> = ({
  disabled = false,
  currentPageSize,
  count,
  setCurrentPageSize,
  setCurrentPage,
  getShowingDataString,
  paginationRender,
  currentPage,
  showChanger = true,
  triggerName = null
}) => {
  const [dataString, setDataString] = useState(getShowingDataString());
  const { projectDetails } = useProjectDetailsStore();
  const { userDetails } = useRbac();
  const {triggerSaveImporter} = useImporterToolStore();
  const { setPromptText, setPromptType } = usePromptText();
  const updateDataString = useCallback(() => {
    setDataString(getShowingDataString());
    if (count >0) {
      setPromptType("polite");
      setPromptText(dataString);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataString, setPromptText, setPromptType, count])
  useEffect(() => {
    updateDataString();
  }, [updateDataString]);
  return (
    <div className="pagerWrap">
      {(count > 0) && showChanger && (
        <div className="pageOption">
          <span>Page size:</span>
            <Select
              value={currentPageSize}
              style={{ width: 80 }}
              onChange={(value: number) => {
                setCurrentPageSize(value);
              }}
              options={pageSizeConfigs.OPTIONS}
            />
          {<span>{dataString}</span>}
        </div>
      )}
      <Pagination
        disabled={disabled}
        pageSize={currentPageSize}
        total={count}
        itemRender={paginationRender}
        showSizeChanger={false}
        onChange={(page) => {
          !disabled && setTimeout(() => {
            triggerName && triggerSaveImporter(triggerName, projectDetails, userDetails);
            setCurrentPage(page);
          }, 200);
        }}
        current={currentPage}
        hideOnSinglePage={true}
      />
    </div>
  );
};

export default Paginator;
