import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Switch,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
import { useEffect, useMemo, useCallback, useRef, useState } from "react";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";
import { useProjectDetailsStore } from "modules/organization/store";
import { dateFormat } from "config/commonConfig";
import { mnDate } from "modules/shared/services/date.service";
import { debounce } from "lodash";
import { ImporterMapperIcon } from "modules/shared/components/CustomAppIcons";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import EditRecord from "./EditRecord";
import { validateJurisdictions } from "./validateJurisdictions";
import { findKey } from "./common";
import { renderIconQuestionType } from "modules/organization/utility/commonFunctions";
import { TooltipParagraph } from "modules/shared/utility/commonFunctions";
import TableSkeleton from "./TableSkeleton";
import DeleteRecordModal from "./DeleteRecordModal";

const Jurisdictions:React.FC<{componentName: string}> = () => {
  const { projectDetails } = useProjectDetailsStore();
  const {
    editingCell,
    showLoader,
    mappingOptions,
    columnMapping,
    dataList,
    currentPage,
    currentPageSize,
    showEditRecordModal,
    showDeleteRecordModal,
    setEditingCell,
    setFinishStep,
    setValidationGlobalErrors,
    setMappingOptions,
    setDataList,
    setColumnMapping,
    setMappingInitValue,
    setShowEditRecordModal,
    setShowDeleteRecordModal,
    setJurisdictionInfo,
    refreshDataList,
    resetDataState,
  } = useImporterToolStore();

  const errorRefs = useRef([] as any);
  const rowRefs = useRef<any>({});
  const editCellRef = useRef<any>(null); // State to track the editing cell
  const [headers, setHeaders] = useState<any>([]);
  const [cautionNotes, setCautionNotes] = useState<string[]>([]);

  const placeType: any = {
    country: "country",
    states: "state",
    state: "state",
    cities: "city",
    city: "city",
    county: "county",
    custom: "custom",
  };

  function getPlaceType(value: string) {
    return value ? placeType[value] || "all" : "state";
  }

  const setErrorRef = useCallback((id: string, ref: any) => {
    if (ref) {
      errorRefs.current[id] = ref;
    }
  }, []);

  const paginatedData = useMemo(() => {
    return dataList?.slice(
      (currentPage - 1) * currentPageSize,
      currentPage * currentPageSize
    );
  }, [dataList, currentPage, currentPageSize]);

  const handleChange = useCallback(
    (rowIndex: number, dataIndex: string, isDynamic: boolean = false) => {
      return debounce((newValue) => {
        setDataList(
          rowIndex,
          isDynamic ? dataIndex : columnMapping[dataIndex],
          newValue
        );
      }, 300);
    },
    [columnMapping]
  );

  const handleCellClick = (rowIndex: number, dataIndex: string) => {
    // Logic to switch to editable mode
    setEditingCell({ rowIndex, dataIndex }); // Assuming you have a state to track the editing cell
    setTimeout(() => {
      editCellRef.current?.focus();
    },100);
  };

  const renderNonEditField = (value: any, choice: string) => {
    if(choice === 'date'){
      return mnDate(value, false).format(dateFormat.default);
    } else if (choice === 'select'){
      return value.join(" || ")
    } else if (choice === 'number'){
      return value;
    } else if (choice === 'currency'){
      return `$ ${value}`;
    } else {
      return value;
    }
  }

  const renderFunction = useCallback(
    (
      value: any,
      record: any,
      type: string,
      dynamic: boolean = false,
      child: any,
      rowIndex: number,
      dataIndex: string
    ) => {
      if (
        editingCell?.rowIndex === rowIndex &&
        editingCell?.dataIndex === dataIndex
      ) {
        if (dynamic) {
          return !child.dataIndex.startsWith("caution") ? (
            ["CAT-ME", "CAT-CA", "BIN"].includes(
              record[`type_${child.dataIndex}`]
            ) ? (
              <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
                <Select
                  key={Date.now()}
                  ref={editCellRef}
                  bordered={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.42)",
                    maxWidth: "281px"
                  }}
                  defaultValue={value ?? undefined}
                  mode={
                    record[`type_${child.dataIndex}`] === "CAT-CA"
                      ? "multiple"
                      : undefined
                  }
                  placeholder="Select option"
                  options={record[`options_${child?.dataIndex}`]?.map(
                    (item: any) => ({
                      value: item,
                      label: item.split("|")[0],
                    })
                  )}
                  onChange={(val) => {
                    if (val !== value) {
                      // Check if value has changed
                      handleChange(rowIndex, dataIndex, true)(val);
                    }
                    setTimeout(() => {
                      return setEditingCell(null);
                    },150);
                  }}
                  allowClear
                />
              </div>
            ) : record[`type_${child.dataIndex}`] === "TEXT" ? (
              <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
                <Input.TextArea
                  key={Date.now()}
                  ref={editCellRef}
                  defaultValue={value}
                  className="importerInputs textAreaWrapper"
                  placeholder="Enter text response"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                  onBlur={(e) => {
                    if (e.target.value !== (value ?? "")) {
                      handleChange(rowIndex, dataIndex, true)(e.target.value);
                    }
                    setTimeout(() => {
                      return setEditingCell(null);
                    },150);
                  }}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  allowClear
                />
              </div>
            ) : record[`type_${child.dataIndex}`] === "DATE" ? (
              <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
                <DatePicker
                  key={Date.now()}
                  ref={editCellRef}
                  defaultValue={value ? mnDate(value, false).date : undefined}
                  className="importerInputs"
                  placeholder="Enter date response"
                  format={dateFormat.default}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                  onBlur={(e) => {
                    if (e.target.value !== (value ?? "")) {
                      handleChange(rowIndex, dataIndex, true)(e.target.value);
                    }
                    setTimeout(() => {
                      return setEditingCell(null);
                    },150);
                  }}
                />
              </div>
            ) : (
              <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
                <InputNumber
                  key={Date.now()}
                  {...(record[`type_${child.dataIndex}`] === "CURRENCY" ? { addonBefore: "$", min: 0 } : {})}
                  ref={editCellRef}
                  defaultValue={value}
                  className="importerInputs"
                  placeholder={`Enter ${ record[`type_${child.dataIndex}`] === "NUMBER" ? 'number' : 'currency'} response`}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                  onBlur={(e) => {
                    let currentValue: string | null = e.target.value;
                    if ( currentValue !== (value ?? "")) {
                      if(record[`type_${child.dataIndex}`] === "CURRENCY"){
                        currentValue = currentValue === "" ? null : (isNaN(+currentValue) ? "0" : ( +currentValue < 0 ? "0" : `${+currentValue}`) )
                      }
                      handleChange(rowIndex, dataIndex, true)(currentValue);
                    }
                    setTimeout(() => {
                      return setEditingCell(null);
                    },150);
                  }}
                />
              </div>
            )
          ) : (
            <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
              <Input.TextArea
                key={Date.now()}
                ref={editCellRef}
                defaultValue={value}
                className="importerInputs textAreaWrapper"
                placeholder="Enter caution note"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                onBlur={(e) => {
                  if (e.target.value !== (value ?? "")) {
                    handleChange(rowIndex, dataIndex, true)(e.target.value);
                  }
                  setTimeout(() => {
                    return setEditingCell(null);
                  },150);
                }}
                autoSize={{ minRows: 2, maxRows: 5 }}
                allowClear
              />
            </div>
          );
        }

        if (type === "date") {
          //
          return (
            <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
              <DatePicker
                key={Date.now()}
                ref={editCellRef}
                defaultValue={value ? mnDate(value, false).date : undefined}
                className="importerInputs"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                aria-label="date"
                placeholder="Pick date"
                format={dateFormat.default}
                onBlur={(e) => {
                  if (
                    e.target.value !==
                    mnDate(value, false).format(dateFormat.default)
                  ) {
                    handleChange(rowIndex, dataIndex)(e.target.value);
                  }
                  setTimeout(() => {
                    return setEditingCell(null);
                  },150);
                }}
              />
            </div>
          );
        } else if (type === "text") {
          return (
            <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
              <Input
                ref={editCellRef}
                key={Date.now()}
                defaultValue={value}
                className="importerInputs"
                placeholder={`Enter ${dataIndex}`}
                style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                onBlur={(e) => {
                  if (e.target.value !== (value ?? "")) {
                    handleChange(rowIndex, dataIndex)(e.target.value);
                  }
                  setTimeout(() => {
                    return setEditingCell(null);
                  },150);
                }}
                allowClear
              />
            </div>
          );
        } else if (type === "select") {
          return (
            <Select
              key={Date.now()}
              ref={editCellRef}
              bordered={false}
              style={{ width: "100%", height: "100%" }}
              defaultValue={value?.length > 0 ? value : null}
              mode={undefined}
              placeholder="Select option"
              options={child.map((item: any) => ({
                value: item,
                label: item,
              }))}
              onChange={(val) => {
                if (val !== value) {
                  // Check if value has changed
                  handleChange(rowIndex, dataIndex)(val);
                }
                setTimeout(() => {
                  return setEditingCell(null);
                },150);
              }}
              allowClear
            />
          );
        }
      }

      let typeElement;
      let choice = dynamic ? record[`type_${child.dataIndex}`]?.toLowerCase() : type?.toLowerCase();
      switch(choice){
        case 'cat-me':
        case 'bin':
          typeElement = 'text';
          break;
        case 'cat-ca':
          typeElement = 'select';
          break;
        case 'currency':
          typeElement = 'currency';
          break;
        case 'number':
          typeElement = 'number';
          break;
        default:
          typeElement = choice;
      }
      // Default to read-only text
      return (
        <div
          tabIndex={0}
          ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}
          onClick={() => handleCellClick(rowIndex, dataIndex)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleCellClick(rowIndex, dataIndex);
          }}
          style={{ cursor: "pointer", padding: "6.5px 11px", height: "100%" }}
        >
          <div
            style={{
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Add ellipsis
              display: '-webkit-box', // For webkit browsers
              WebkitBoxOrient: 'vertical', // Set box orientation
              overflowY: 'hidden', // Hide vertical overflow
              WebkitLineClamp: 7, // Limit to 7 lines
            }}
          >
            {value?.length > 0 ? renderNonEditField(value, typeElement) : <span style={{color: '#797979'}}>No Data</span>}
          </div>
        </div>
      );
    },
    [handleChange, editingCell]
  );

  const renderTitleFunction = (title: string, dataIndex: string) => {
    return (
      <div className="displayFlex flexAlignCenter flexJSpaceB">
        <div className="importerHeaderTitle">{title}</div>
        {!mappingOptions.some((each: any) => each.value === dataIndex) && (
          <>
            <div style={{ padding: "2px 5px 0 9px" }}>
              <ImporterMapperIcon />
            </div>
            <Select
              key={dataIndex}
              value={columnMapping[dataIndex] === dataIndex ? null : columnMapping[dataIndex]}
              bordered={false}
              placeholder="Select option"
              options={mappingOptions}
              onChange={(value) => {
                setColumnMapping(dataIndex, value);
              }}
              style={{ maxWidth: "150px" }}
            />
          </>
        )}
      </div>
    );
  };

  const handleSwitchChange = (child: any) => {
    if(cautionNotes.includes(child.dataIndex)) {
      setCautionNotes((prev) => {
        const updateValues = prev.filter(value => value !== child.dataIndex);
        return updateValues;
      })
    } else {
      setCautionNotes((prev) => {
        return [
          ...prev,
          `${child.dataIndex}`
        ]
      })
    }
  }

  const cautionRenderFunction = (child: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {child.title} 
        {!child.dataIndex.startsWith("caution") ? (
          <Switch 
            defaultChecked={cautionNotes.includes(child.dataIndex)}
            onChange={() => handleSwitchChange(child)} 
            disabled={dataList.map(item => item[`caution_note_${child.dataIndex}`]).some(value => (value?.trim() === '' ? null : value) !== null)}
            checkedChildren={"Caution"}
            unCheckedChildren={"Caution"}
          />
        ) : ''}
      </div>
    );
  }

  const columns: TableColumnsType<any> = [
    {
      title: "",
      children: [
        {
          title: "Jurisdiction",
          dataIndex: columnMapping["jurisdiction"],
          key: "jurisdiction",
          width: 280,
          fixed: "left",
          render: (value: any, record: any, rowIndex: number) => {
            return (
              <div
                key={rowIndex}
                className="displayFlex flexAlignCenter"
                style={{ gap: "5px", padding: "0 11px" }}
                ref={(ref) => setErrorRef(`jurisdiction_${rowIndex}`, ref)}
              >
                <div style={{ flexGrow: "1" }}>{value}</div>
                <Tooltip
                  title={record.place_id ? "Verified" : "Not Verified"}
                  trigger={["hover", "focus"]}
                >
                  {record.place_id ? (
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      aria-label="verified"
                      role="img"
                    />
                  ) : (
                    <CloseCircleTwoTone
                      twoToneColor="red"
                      aria-label="not verified"
                      role="img"
                    />
                  )}
                </Tooltip>
                <Button
                  className="iconOnlyBtn smallIcon"
                  onClick={() => {
                    setShowEditRecordModal(true);
                    setJurisdictionInfo({
                      rowIndex: rowIndex,
                      place_id: record.place_id,
                      jurisdiction_id: record.jurisdiction_id,
                      country: record[columnMapping.country],
                      jurisdiction_name: record[columnMapping.jurisdiction],
                      place: {
                        type: getPlaceType(
                          record[columnMapping.jurisdiction_types]
                        ),
                        jurisdiction_value: record.place_id
                          ? record[columnMapping.jurisdiction]
                          : null,
                      },
                    });
                  }}
                >
                  <EditOutlined aria-label="edit" />
                </Button>
                <Button
                  className="iconOnlyBtn smallIcon"
                  onClick={() => {
                    setShowDeleteRecordModal(true);
                    setJurisdictionInfo({
                      key: record.key,
                      jurisdiction_name: record[columnMapping.jurisdiction],
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "Jurisdiction types",
          dataIndex: columnMapping["jurisdiction_types"],
          key: "jurisdiction_types",
          width: 150,
          render: (value: any) => {
            return <div style={{ padding: "0 11px" }}>{value}</div>;
          },
        },
        {
          title: "Country",
          dataIndex: columnMapping["country"],
          key: "country",
          width: 200,
          render: (value: any, record: any) => {
            return (
              <div style={{ padding: "0 11px" }}>
                {record[columnMapping.jurisdiction_types] === "country"
                  ? ""
                  : value}
              </div>
            );
          },
        },
        {
          title: renderTitleFunction("Series", "series"),
          dataIndex: columnMapping["series"],
          key: "series",
          width: 230,
          render: (value: any, record: any, rowIndex: number) =>
            renderFunction(
              value,
              record,
              "text",
              false,
              [],
              rowIndex,
              "series"
            ),
        },
        {
          title: renderTitleFunction("Effective From", "effective_from"),
          dataIndex: columnMapping["effective_from"],
          key: "effective_from",
          width: 300,
          render: (value: any, record: any, rowIndex: number) =>
            renderFunction(
              value,
              record,
              "date",
              false,
              [],
              rowIndex,
              "effective_from"
            ),
        },
        {
          title: renderTitleFunction("Through to", "through_to"),
          dataIndex: columnMapping["through_to"],
          key: "through_to",
          width: 300,
          render: (value: any, record: any, rowIndex: number) =>
            renderFunction(
              value,
              record,
              "date",
              false,
              [],
              rowIndex,
              "through_to"
            ),
        },
      ],
    },
  ];

  const mapHeaderList = (header: []) => {
    return header.slice(1).map((item: any) => {
      return {
        ...item,
        title: <div className="displayFlex">
            <Tooltip title={item.type} trigger={['hover','focus']}><span style={{ position: 'relative', top: '3px', marginRight: '4px' }}>{item.type ? renderIconQuestionType(item.type) : ''}</span></Tooltip>
            <TooltipParagraph tabIndex={-1} toLink={null}
            style={{ flex: '1', textAlign: 'left' }}
            >
              {item.title}
            </TooltipParagraph>
        </div>,
        children: item.children.map((child: any) => {
          return {
            title: cautionRenderFunction(child),
            dataIndex: child.dataIndex,
            key: child.dataIndex,
            width: 300,
            render: (value: any, record: any, rowIndex: number) =>
              renderFunction(
                value,
                record,
                "",
                true,
                child,
                rowIndex,
                child.dataIndex
              ),
          };
        }),
      };
    });
  };

  useEffect(() => {
    const fetchJurisdictionData = async () => {
      try {
        const result =
          await new ProjectImporterToolService().getImporterToolJurisdiction(
            projectDetails?.slug ?? ""
          );
        const { jurisdictionData } = result?.data.data;
        const { jurisdiction_data, config_data, finished_step } = jurisdictionData;
        const { header, data } = jurisdiction_data;

        const columnMapping = findKey(config_data, 'jurisdiction_mapping')
        if(columnMapping){
          setMappingInitValue(columnMapping)
        }

        setFinishStep(finished_step);

        setMappingOptions(
          header[0]?.children?.map((each: any) => {
            if(each.dataIndex === 'jurisdiction') return null;
            return {
              label: each.title,
              value: each.dataIndex,
            };
          }).filter((each: any) => each !== null)
        );
        const mapHeader = mapHeaderList(header);
        setHeaders(header);
        refreshDataList(mapHeader, header, data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchJurisdictionData();

    return () => {
      resetDataState();
    };
  }, []);

  const onRow = (_: any, index: number) => ({
    ref: (node: any) => {
      if (node) {
        rowRefs.current[index] = node;
      }
    },
  });

  useEffect(() => {
    if (dataList?.length > 0) {
      dataList.forEach((item) => {
        const keys = Object.keys(item).filter(each => each.startsWith("caution"));
        keys.forEach((key) => {
          if(item[key] && !(cautionNotes.includes(key.replace("caution_note_","")))) {
            setCautionNotes((prev) => {
              return [
                ...prev,
                `${key.replace("caution_note_","")}`
              ]
            })
          }
        })
      });
      validateJurisdictions({
        dataList,
        currentPage,
        currentPageSize,
        columnMapping,
        rowRefs,
        errorRefs,
        setValidationGlobalErrors,
      });
    }
  }, [dataList, currentPage, columnMapping, headers]);

  useEffect(() => {
    setHeaders((prev: any) => {
      const staticHeader = prev[0];
      const newDynamicHeaders = prev.slice(1).map((each: any) => {
        const questionVarDataIndex = each.children[0].dataIndex;
        if(cautionNotes.includes(questionVarDataIndex)) {
          return {
            ...each,
            children : [ each.children[0], {
              "title": "Caution Note",
              "dataIndex": `caution_note_${questionVarDataIndex}`,
              "key": `caution_note_${questionVarDataIndex}`
            }]
          }
        } else {
          return {
            ...each,
            children : [ each.children[0] ]
          }
        }
      })
      return [
        staticHeader,
        ...newDynamicHeaders
      ]
    });
  }, [cautionNotes])

  if (!projectDetails) return <>Loading...</>;

  return (
    <>
      {
        showLoader? 
        <TableSkeleton rows={10} columns={10} /> :
        <Table
          columns={[...columns, ...mapHeaderList(headers)]}
          dataSource={paginatedData as any}
          bordered
          size="small"
          pagination={false}
          scroll={{ x: "max-content", y: "calc(100vh - 418px)" }}
          onRow={onRow as any}
          tableLayout="auto"
        />
      }
      {showEditRecordModal && <EditRecord />}
      {showDeleteRecordModal && <DeleteRecordModal/>}
    </>
  );
};

export default Jurisdictions;
