import ReactTextareaAutocomplete, {
  ItemComponentProps
} from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import { Button } from 'antd';
import { FC, TextareaHTMLAttributes, useEffect, useState } from 'react';
import { EquationParser } from '../../utils/equationParser';
import { IPQuestion } from 'modules/organization/models/interface';
import { RankingToolState, runRanking, useRankingToolStore } from 'modules/organization/store';
import { useShallow } from "zustand/react/shallow";

interface IQuestionVariable {
  id: number;
  variable: string;
  question: string;
  question_order_text: string;
}

interface IQuestionResponseVariable {
  id: number;
  variable: string;
  question: string;
}

interface IOperator {
  name: string;
}

const RankingToolsEditor: FC<{ questions?: IPQuestion[] }> = ({
  questions
}) => {
  const ranks = useRankingToolStore(useShallow((state: RankingToolState) => state.ranks));
  const currentRankTab = useRankingToolStore((state: RankingToolState) => state.currentRankTab);
  const currentEquation = useRankingToolStore(useShallow((state: RankingToolState) => state.currentEquation));
  const allEquations = useRankingToolStore(useShallow((state: RankingToolState) => state.allEquations));
  const setCurrentTab = useRankingToolStore((state: RankingToolState) => state.setCurrentTab);
  const setEquationText = useRankingToolStore((state: RankingToolState) => state.setEquationText);
  const [questionVariableList, setQuestionVariableList] = useState<
    IQuestionVariable[]
  >([]);
  const [questionResponseVariableList, setQuestionResponseVariableList] =
    useState<IQuestionResponseVariable[]>([]);
  const [operatorList, setOperatorList] = useState<IOperator[]>([
    { name: 'AND' },
    { name: 'QAND' },
    { name: 'OR' },
    { name: 'QOR' },
    { name: 'EQUAL' },
    { name: 'NOTEQUAL' },
    { name: 'COMPARE' },
    { name: 'QHASANSWER' },
    { name: 'QHASNOTANSWER' },
    { name: 'IMPORT' }
  ]);

  let textAreaRef: ReactTextareaAutocomplete<
    string | object,
    TextareaHTMLAttributes<HTMLTextAreaElement>
  > | null = null;

  useEffect(() => {
    if (questions) {
      const _buildQuestionVariableList: IQuestionVariable[] = [];
      const _buildQuestionResponseVariableList: IQuestionResponseVariable[] =
        [];

      questions.forEach((eachQuestion) => {
        _buildQuestionVariableList.push({
          id: eachQuestion.id,
          variable: eachQuestion.question_variable,
          question: eachQuestion.question,
          question_order_text: eachQuestion.question_order_text
        });

        eachQuestion.responses.forEach((eachQResponse) => {
          _buildQuestionResponseVariableList.push({
            id: eachQResponse.id,
            variable: eachQResponse.response_variable,
            question: eachQuestion.question
          });
        });
      });

      setQuestionVariableList(_buildQuestionVariableList);
      setQuestionResponseVariableList(_buildQuestionResponseVariableList);
    }
  }, [questions]);

  const operatorDataProvider = (token: string) => {
    return operatorList
      .filter((each) => each.name.toLowerCase().includes(token.toLowerCase()))
      .map((each) => {
        return {
          ...each,
          caretPosition: 'start'
        };
      });
  };

  const questionDataProvider = (token: string) => {
    return questionVariableList.filter(
      (each) =>
        each.variable.toLowerCase().includes(token.toLowerCase()) ||
        each.question.toLowerCase().includes(token.toLowerCase())
    );
  };

  const questionResponseDataProvider = (token: string) => {
    return questionResponseVariableList.filter(
      (each) =>
        each.variable.toLowerCase().includes(token.toLowerCase()) ||
        each.question.toLowerCase().includes(token.toLowerCase())
    );
  };

  const [equationError, setEquationError] = useState(0);

  const handleChangeEquation = (data: string) => {
    setEquationText(data);
  };

  useEffect(() => {
    const equationParsedObj = new EquationParser(currentEquation?.equation ?? "");
    setEquationError(equationParsedObj.getHasError());
  }, [currentEquation?.equation]);

  const getResult = async () => {
    try {
      const result = await runRanking(allEquations, questions ?? [], 1, 19379);
      alert(JSON.stringify(result));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="tabContainer">
      <div className="tabMenu" role="tablist" aria-labelledby="tab-header">
        {ranks.map((rank) => (
          <Button
            onClick={() => setCurrentTab(rank)}
            key={rank}
            className={rank === currentRankTab ? 'active' : ''}
          >
            {rank}
          </Button>
        ))}
      </div>
      <div className="tabContent">
        <h3>Build Equation</h3>
        <ReactTextareaAutocomplete
          value={currentEquation?.equation ?? ""}
          className="rankingTextarea"
          style={{ borderColor: equationError > 0 ? 'red' : '#969696' }}
          onChange={(e) => {
            handleChangeEquation(e.target.value);
          }}
          loadingComponent={() => <span>Loading</span>}
          placeholder="Start build equation"
          data-grammar="false"
          data-gramm="false"
          data-gramm_editor="false"
          data-enable-grammarly="false"
          trigger={{
            '#': {
              dataProvider: questionResponseDataProvider,
              component: (item: any) => {
                return <div className="entryName">{item.entity.variable}</div>;
              },
              output: (item: any, trigger) => item.variable
            },
            '@': {
              dataProvider: questionDataProvider,
              component: (item: any) => {
                return (
                  <>
                    <div className="entryName">{item.entity.variable}</div>
                    <div>
                      {item.entity.question_order_text + ')'}{' '}
                      {item.entity.question}
                    </div>
                  </>
                );
              },
              output: (item: any, trigger) => item.variable
            },
            ':': {
              dataProvider: operatorDataProvider,
              component: (item: ItemComponentProps<any>) => (
                <>{`${item.entity?.name}`}</>
              ),
              output: (item: any, trigger) => {
                return {
                  key: item.name,
                  text: item.name + '()',
                  caretPosition: 'end'
                };
              }
            }
          }}
          onItemSelected={(data) => {
            if (data.currentTrigger === ':') {
              setTimeout(() => {
                textAreaRef?.setCaretPosition(
                  textAreaRef?.getCaretPosition() - 1
                );
              }, 100);
            }
          }}
          movePopupAsYouType={true}
          minChar={0}
          // onCaretPositionChange={(num) => {
          //   console.log(num);
          // }}
          ref={(rta) => {
            if (rta?.getCaretPosition()) {
              textAreaRef = rta;
            }
          }}
        />
        {equationError > 0 ? (
          <div style={{ color: 'red' }}>Equation has syntax error</div>
        ) : (
          ''
        )}

        <div className="btnWrap">
          <Button className="linkBtn2">Operation Guide</Button>
          <Button>Clear</Button>
          <Button type="primary">Save</Button>
          <Button
            type="primary"
            // disabled={equationError > 0 || equationText.trim() === ''}
            onClick={getResult}
          >
            Get Result
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RankingToolsEditor;
